// PerformanceChart.js
import React, { useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PerformanceChart = ({ data }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chart = chartRef.current;
        if (chart) {
            const ctx = chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
            gradient.addColorStop(0, 'rgba(123, 31, 162, 0.5)');
            gradient.addColorStop(1, 'rgba(123, 31, 162, 0)');
            
            chart.data.datasets[0].backgroundColor = gradient;
            chart.update();
        }
    }, [data]);

    const chartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Performance (%)',
                data: data,
                fill: true,
                borderColor: 'rgba(123, 31, 162, 1)',
                pointBackgroundColor: 'rgba(123, 31, 162, 1)',
                pointBorderColor: '#fff',
                tension: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: 'Your Performance (%)',
                align: 'start',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return <Line ref={chartRef} data={chartData} options={options} />;
};

export default PerformanceChart;
