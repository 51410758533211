import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCart } from '../../redux/merch/fetchCart';
import { fetchWithSession } from '../../redux/session';
import { IoCartOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const Cart = () => {
  const { cart, loadingCart } = useSelector((state) => state.getCart);
  console.log("cart", cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const api_url = process.env.REACT_APP_BASE_API_URL;

  const [quantity, setQuantity] = useState({});
  const [activeProduct, setActiveProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState("");
  const [price, setPrice] = useState(0);
  const [productId, setProductId] = useState("");
  const userId = localStorage.getItem('userId');
  const totalQuantityProduct = cart?.cart?.items?.reduce((product, item) => product + item.quantity, 0);

  useEffect(() => {
    if (userId) {
      dispatch(fetchCart(userId));
    }
  }, [dispatch, userId]);

  const updateQuantity = (productId, change) => {
    setActiveProduct(productId);
    setQuantity((prevQuantities) => ({
      ...prevQuantities,
      [productId]: Math.max((prevQuantities[productId] || 0) + change, 0),
    }));
  };

  const handleCartUpdate = async () => {
    if (!productId) {
      alert("Please set a quantity.");
      return;
    }

    if (!quantity[activeProduct]) {
      alert("Quantity is not set. Please set a quantity.");
      return;
    }

    const url = `${api_url}/api/add-to-cart`;

    const options = {
      method: 'POST',
      body: {
        userId,
        productId,
        selectedSize,
        quantity: quantity[activeProduct],
        price
      },
    };

    try {
      const response = await fetchWithSession(url, options);
      if (response.status === 200) {
        dispatch(fetchCart(userId));
        alert('Product added to cart successfully');
        setQuantity((prev) => ({ ...prev, [activeProduct]: 0 }));
        setSelectedSize("");
        setPrice(0);
      } else {
        alert('Failed to add product to cart');
        setQuantity((prev) => ({ ...prev, [activeProduct]: 0 }));
        setSelectedSize("");
        setPrice(0);
      }
    } catch (error) {
      setQuantity((prev) => ({ ...prev, [activeProduct]: 0 }));
      setSelectedSize("");
      setPrice(0);
      alert('Error occurred while adding product to cart');
    }
  };

  const increaseQuantity = (productId, price, size) => {
    setProductId(productId);
    setPrice(price);
    setSelectedSize(size);
    updateQuantity(productId, 1);
  };

  const decreaseQuantity = (productId, price, size) => {
    setProductId(productId);
    setPrice(price);
    setSelectedSize(size);
    updateQuantity(productId, -1);
  };

  const removeCart = (id, size) => {
    handleRemoveFromCart(id, size);
  }

  const handleRemoveFromCart = async (id, size) => {
    const url = `${api_url}/api/remove-from-cart`;

    const options = {
      method: 'POST',
      body: {
        userId,
        productId: id,
        selectedSize: size,
      },
    };

    try {
      const response = await fetchWithSession(url, options)
      console.log("response", response);

      if (response.status === 200) {
        dispatch(fetchCart(userId));
        alert('Product removed from cart successfully');
      } else {
        alert('Failed to remove product from cart');
      }
    } catch (error) {
      console.log("error", error);
      alert('Error occurred while removing product from cart');
    }
  };


  return (
    <section className="px-[4%] pb-[4%]">
      {loadingCart && (
        <p className="font-[400] text-[20px] workSans pt-5 pb-3 text-[#011114]">Loading cart...</p>
      )}
      <div className="flex items-center justify-between gap-4 bg-[#fff]">
        <div className="py-6">
          <h1 className="text-[#121212] text-[28px] font-[700] workSans">Shop Merch</h1>
        </div>
        <div className="flex items-center gap-2 hover:text-[#E20BE3]">
          <div className="flex items-start">
            <IoCartOutline className="font-[600] text-[30px]" />
            {cart?.cart?.items?.length > 0 && (
              <div className="bg-[#E20BE3] rounded-[50%] ml-[-10px] text-[9px] font-[600] text-[#fff] px-[4px] py-[1px]">{totalQuantityProduct}</div>
            )}
          </div>
          <p className="text-[18px] font-[400] workSans">Cart</p>
        </div>
      </div>
      <div>
        {cart && cart?.cart?.items.length > 0 ? (
          <div className="lg:flex items-start my-2 gap-4 justify-between">
            <div>
              {cart.cart.items.map((product) => (
                <div className="bg-[#5900641A] lg:flex mb-2 items-center gap-4 rounded-[24px] p-[24px]" key={product._id}>
                  <img
                    className="w-[full] h-[250px] custom-product"
                    src={product.productId.productimage}
                    alt={product.productId.productname}
                  />
                  <div className="">
                    <p className="font-[600] text-[18px] workSans pt-5 pb-3 text-[#011114]">{product.productId.productname}</p>
                    <div>
                      <p className="text-[#011114] font-[400] text-[12px] workSans">Quantity {product.quantity}</p>
                      {product.selectedSize && (
                        <p className="text-[#011114] font-[400] text-[12px] workSans">Size {product.selectedSize}</p>
                      )}
                      <div className={`flex items-center gap-4`}>
                        <p className="text-[#011114] font-[400] text-[16px] workSans">Add quantity</p>
                        <div className="flex gap-2">
                          <button onClick={() => increaseQuantity(product.productId._id, product.price, product.selectedSize)} type="button" className="bg-[#E20BE3] rounded-[4px] px-[6px] font-[700] text-[14px] text-[#fff] workSans">+</button>
                          <div className="border-[1px] border-[#D9D9D9] rounded-[4px] px-[6px] font-[700] text-[14px] text-[#011114] workSans">{quantity[product.productId._id] || 0}</div>
                          <button onClick={() => decreaseQuantity(product.productId._id, product.price, product.selectedSize)} type="button" className="bg-[#E20BE3] rounded-[4px] px-[6px] font-[700] text-[14px] text-[#fff] workSans">-</button>
                          <button
                            type="button"
                            onClick={handleCartUpdate}
                            className="bg-[#011114] rounded-[5px] mt-[5px] px-[4px] py-[2px] font-[600] text-[14px] text-[#fff] workSans"
                          >
                            Add to cart
                          </button>
                        </div>
                      </div>
                    </div>
                    <p className="font-[700] text-[24px] text-[#011114] mb-4 workSans">$ {product.price}</p>
                    <div onClick={() => removeCart(product.productId._id, product.selectedSize)} className="flex gap-2 items-center text-[#C30000] cursor-pointer w-fit">
                      <MdDeleteOutline />
                      <p className="font-[500] text-[18px] workSans">Remove</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="bg-[#5900641A] rounded-[24px] p-[24px] top-0 sticky">
              <p className="font-[400] text-[18px] workSans">Cart Summary</p>
              <hr />
              <div className="flex items-center my-2 gap-2 justify-between">
                <p className="font-[400] text-[15px] workSans">Sub total</p>
                <p className="font-[500] text-[18px] workSans">$ {cart.cart.totalPrice}</p>
              </div>
              <hr />
              <button
                onClick={() => navigate("/checkout/summary")}
                className="bg-[#E20BE3] w-full rounded-[11px] py-[12px] px-[96px] workSans font-[600] text-[14px] text-[#fff]"
              >
                Checkout ($ {cart.cart.totalPrice})
              </button>
            </div>
          </div>
        ) : (
          <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingCart ? "" : "No products found"}</div>
        )}
      </div>
    </section>
  )
}

export default Cart
