import React from 'react';

const Tether = ({ setPage }) => {
  return (
    <div>
      <button
        onClick={() => setPage(0)}
        type="button"
        className={`px-2 py-[4px] border-[#616161] text-[#011114] border-[1px] bg-[#fff] rounded-[50%]`}
      >
        &larr;
      </button>
      <p className="block workSans my-4">Tether</p>
    </div>
  );
};

export default Tether;
