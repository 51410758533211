import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithSession } from '../session';

const GET_VIDEOS = "GET_VIDEOS";
const api_url = process.env.REACT_APP_BASE_API_URL;
const GET_VIDEOS_URL = `${api_url}/api/livevideos/get`;

const initialState = {
  videos: [],
  loadingVideos: false,
  videosError: false,
}

export const fetchVideos = createAsyncThunk(
  GET_VIDEOS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWithSession(`${GET_VIDEOS_URL}`, {
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const getVideosReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_VIDEOS}/pending`:
      return {
        ...state,
        loadingVideos: true,
        videosError: false
      }
    case `${GET_VIDEOS}/fulfilled`:
      return {
        videos: action.payload,
        loadingVideos: false,
        videosError: false
      }
    case `${GET_VIDEOS}/rejected`:
      return {
        ...state,
        loadingVideos: false,
        videosError: true
      }
    default:
      return { ...state, loadingVideos: false, videosError: false };
  }
}

export default getVideosReducer;
