import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../redux/auth/getUser';

const Profile = () => {
  const { user, loadingUser, userError } = useSelector((state) => state.getUser);
  const dispatch = useDispatch();
  const userId = localStorage.getItem('userId');
  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);

  return (
    <div className="p-[4%]">
      {loadingUser && (
        <p className="text-[20px] font-[400] workSans text-gray-900 mb-6">Loading...</p>
      )}
      {userError && (
        <p className="text-[20px] font-[400] workSans text-gray-900 mb-6">Error loading user, please check your internet connection and refresh the page</p>
      )}
      <h2 className="text-[24px] font-[700] workSans text-center text-gray-900 mb-6">Personal information</h2>
      <div className="flex justify-center">
        {user && (
          <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-3xl md:flex items-center justify-between">
            <div className="flex flex-col md:items-center">
              <img
                src={`https://robohash.org/${user.firstname}.png?size=112x112`}
                alt="Profile Avatar"
                className="w-28 h-28 rounded-full mb-4"
              />
            </div>

            <div className="w-2/3">
              <div className="flex flex-wrap justify-between mb-4">
                <div className="w-1/2 pr-2">
                  <p className="text-[14px] font-[700] workSans text-gray-600 mb-1">First name</p>
                  <p className="text-[16px] font-[400] workSans text-gray-900">{user.firstname}</p>
                </div>
                <div className="w-1/2 pl-2">
                  <p className="text-[14px] font-[700] workSans text-gray-600 mb-1">Last name</p>
                  <p className="text-[16px] font-[400] workSans text-gray-900">{user.lastname}</p>
                </div>
              </div>

              <div className="mb-4">
                <p className="text-[14px] font-[700] workSans text-gray-600 mb-1">Username</p>
                <p className="text-[16px] font-[400] workSans text-gray-900">{user.username}</p>
              </div>

              <div className="mb-4">
                <p className="text-[14px] font-[700] workSans text-gray-600 mb-1">Email address</p>
                <p className="text-[16px] font-[400] workSans text-gray-900">{user.email}</p>
              </div>

              <div className="mb-4">
                <p className="text-[14px] font-[700] workSans text-gray-600 mb-1">Modules completed</p>
                <p className="text-[16px] font-[400] workSans text-gray-900">{user.completedModuleContents}</p>
              </div>

              {/* <div className="flex justify-between items-center mt-6">
              <button className="bg-gradient-to-r from-pink-500 to-purple-500 text-white py-2 px-4 rounded-full text-sm font-[500] workSans">
                Edit info
              </button>
              <button className="border-2 border-pink-500 text-pink-500 py-2 px-4 rounded-full text-sm font-[500] workSans">
                Change password
              </button>
            </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Profile;
