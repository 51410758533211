import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logowhite from "./images/logowhite2.png";
import { useNavigate } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../@shadcomponents/ui/alert-dialog";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineShoppingCart } from "react-icons/md";
import { LuShoppingBag } from "react-icons/lu";
import { MdLiveTv } from "react-icons/md";
import { BiSolidVideos } from "react-icons/bi";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { LuCalendarDays } from "react-icons/lu";
import { CgProfile } from "react-icons/cg";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { FaRegBell } from "react-icons/fa6";
import { TbLogout2 } from "react-icons/tb";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const tabNum = sessionStorage.getItem("tabNum");
  const [activeIndex, setActiveIndex] = useState(parseFloat(tabNum) || 0);
  const navigate = useNavigate();
  const menuItems = [
    { name: 'Dashboard', icon: <MdOutlineDashboard />, link: '/' },
    { name: 'Video courses', icon: <BiSolidVideos />, link: '/video_courses' },
    { name: 'Live session', icon: <MdLiveTv />, link: '/live_session' },
    { name: 'Resources', icon: <MdOutlineLibraryBooks />, link: '/resources' },
    { name: 'Upcoming events', icon: <LuCalendarDays />, link: '/events' },
  ];

  const merchItems = [
    { name: 'Merch', icon: <MdOutlineShoppingCart />, link: '/merch' },
    { name: 'Orders', icon: <LuShoppingBag />, link: '/orders' }
  ]

  const accountItems = [
    { name: 'Profile', icon: <CgProfile />, link: '/profile' },
    { name: 'Contact us', icon: <IoMdHelpCircleOutline />, link: '/contact-us' },
    { name: 'Notifications', icon: <FaRegBell />, notificationCount: "" },
  ];

  const handleActiveLink = (num) => {
    sessionStorage.setItem("tabNum", num);
    setActiveIndex(num);
    toggleSidebar();
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('streamToken');
    localStorage.removeItem('viewedVideos');
    window.location.reload();
    navigate('/auth/signin');
  }

  return (
    <div className={`h-screen z-50 overflow-y-auto w-[13.8rem] fixed max-w-[13.8rem] top-0 left-0 sidebar_bg text-white transition-transform transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 md:flex flex-col justify-between p-4 sidebar_scrollbar`}>
      <div>
        <div className="flex items-center mb-12 mt-2">
          <img src={logowhite} alt="Logo" className="py-[10%] w-[150px] m-auto" />
        </div>
        <div>
          <ul className="">
            {menuItems.map((item, index) => (
              <Link key={index} to={item.link}>
                <li
                  className={`flex items-center px-[16px] py-2 text-[15px] font-[500] workSans mb-4 rounded-[8px] cursor-pointer ${activeIndex === index ? 'bg-white text-[#590064]' : 'hover:bg-purple-700'}`}
                  onClick={() => handleActiveLink(index)}
                >
                  <span className="mr-2 text-[20px]">{item.icon}</span>
                  {item.name}
                </li>
              </Link>
            ))}
          </ul>
          <p className="text-[16px] py-4 font-[500] workSans px-[16px]">SHOP</p>
          <ul className="">
            {merchItems.map((item, index) => (
              <Link key={index} to={item.link}>
                <li
                  className={`flex items-center px-[16px] py-2 text-[15px] font-[500] workSans mb-4 rounded-[8px] cursor-pointer ${activeIndex === index + menuItems.length ? 'bg-white text-[#590064]' : 'hover:bg-purple-700'}`}
                  onClick={() => handleActiveLink(index + menuItems.length)}
                >
                  <span className="mr-2 text-[20px]">{item.icon}</span>
                  {item.name}
                </li>
              </Link>
            ))}
          </ul>
          <div className="mt-4 mb-4">
            <p className="text-[16px] font-[500] workSans py-4 px-[16px]">ACCOUNT</p>
            <ul>
              {accountItems.map((item, index) => (
                <Link key={index} to={item.link}>
                  <li
                    className={`flex items-center px-[16px] py-2 text-[15px] font-[500] workSans mb-4 rounded-[8px] cursor-pointer ${activeIndex === index + menuItems.length + merchItems.length ? 'bg-white text-[#590064]' : 'hover:bg-purple-700'}`}
                    onClick={() => handleActiveLink(index + menuItems.length + merchItems.length)}
                  >
                    <span className="mr-2 text-[20px]">{item.icon}</span>
                    {item.name}
                    {item.notificationCount && (
                      <span className="ml-2 bg-white text-purple-600 rounded-full px-2 py-0.5 text-xs font-bold">
                        {item.notificationCount}
                      </span>
                    )}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
          <AlertDialog>
            <AlertDialogTrigger>
              <li
                className={`flex items-center w-screen px-[16px] py-2 text-[15px] font-[500] workSans mb-4 rounded-[8px] cursor-pointer`}
              >
                <TbLogout2 className="mr-2 text-[20px]" />
                Logout
              </li>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Logout of your account?</AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to log out? You will need to sign in again to access your account.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction>
                  <button type="button" onClick={handleLogout}>Continue</button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

