import React from 'react';
import logo from "../../images/logoblack.png";
import { Link } from 'react-router-dom';

const Help = ({ setPage }) => {
  return (
    <div>
      <Link to="/"><img src={logo} alt="logo" /></Link>
      <p className="text-[#011114] text-[30px] font-[700] workSans mt-3">Identity verification</p>
      <p className="mb-4 text-[#011114] text-[16px] pb-4 font-[400] workSans">
        Our system detected you to be male, if you feel it's not correct please click on the button below to upload a valid ID to verify your gender.
      </p>
      <button
        type="button"
        onClick={() => setPage(7)}
        className="linearGradient rounded-[32px] w-full text-[14px] font-[500] workSans text-[#fff]"
      >
        Upload ID
      </button>
    </div>
  );
};

export default Help;
