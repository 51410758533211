import { configureStore } from '@reduxjs/toolkit';
import signupReducer from "./auth/signup";
import verifyOtpReducer from "./auth/verifyOtp";
import sendOtpReducer from "./auth/sendOtp";
import signinReducer from './auth/signin';
import getEventsReducer from './events/events';
import getResourcesReducer from './resources/resources';
import getUserReducer from './auth/getUser';
import createVideoReducer from './videos/uploadVideo';
import getVideosReducer from './videos/getVideos';
import getMerchReducer from './merch/merch';
import getCartReducer from './merch/fetchCart';
import getOrdersReducer from './merch/order';

const store = configureStore({
  reducer: {
    signup: signupReducer,
    signin: signinReducer,
    verifyOtp: verifyOtpReducer,
    sendOtp: sendOtpReducer,
    getEvents: getEventsReducer,
    getResources: getResourcesReducer,
    getUser: getUserReducer,
    postVideo: createVideoReducer,
    getVideos: getVideosReducer,
    getMerch: getMerchReducer,
    getCart: getCartReducer,
    getOrders: getOrdersReducer,
  }
})

export default store;
