import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import logo from "../../images/logoblack.png";
import { useParams, Link } from 'react-router-dom';
import uploadIcon from "../../images/publish.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { getUserIdFromLocalStorage } from './getUserId';

const UploadID = ({ setPage }) => {
  const [imageFiles, setImageFiles] = useState([]);
  const userId = getUserIdFromLocalStorage();
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const [imagesPreview, setImagesPreview] = useState([]);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingFile, setIsSendingFile] = useState(false);
  const fileInputRef = useRef(null);

  const navigate = useNavigate();
  const handleBack = () => {
    window.location.reload();
    navigate("/");
  };

  const handleFileSelection = (selectedFiles) => {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];

    const validFiles = Array.from(selectedFiles).filter((file) =>
      allowedFileTypes.includes(file.type)
    );
    if (validFiles[0].size > 50 * 1024) {
      toast.warning('File size exceeds 50KB limit', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (validFiles?.length > 0) {
      setIsLoading(true);
      setTimeout(() => {
        setImageFiles(validFiles);
        const imageUrls = validFiles.map((file) => URL.createObjectURL(file));
        setImagesPreview(imageUrls);
        setIsLoading(false);
      }, 4000)
    } else {
      toast.warning('No valid file types selected. Please select PNG, JPEG, or BMP files.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles?.length > 0) {
      const updatedFiles = [...imageFiles, ...selectedFiles];
      handleFileSelection(updatedFiles);
    }
  };

  useEffect(() => {
    return () => {
      imageFiles.forEach((file) => URL.revokeObjectURL(file));
    };
  }, [imageFiles]);

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles?.length > 0) {
      const updatedFiles = [...imageFiles, ...droppedFiles];
      handleFileSelection(updatedFiles);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async () => {
    setIsSendingFile(true);
    if (imageFiles.length === 0) {
      toast.warning('Please select a file', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsSendingFile(false);
      return;
    }

    const file = imageFiles[0];

    // Check file size (200KB = 200 * 1024 bytes)
    if (file.size > 200 * 1024) {
      toast.warning('File size exceeds 200KB limit', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsSendingFile(false);
      return;
    }

    const formData = new FormData();
    formData.append('userValidId', file);
    for (const pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    try {
      const response = await axios.post(`${api_url}/api/auth/upload-id/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      toast.success(response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsSendingFile(false);
      setTimeout(() => {
        setPage(8);
      }, 2000)
    } catch (error) {
      toast.error(error.response?.data?.message || 'Something went wrong', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsSendingFile(false);
    }
    setIsSendingFile(false);
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Link to="/"><img src={logo} alt="logo" /></Link>
      <p className="text-[#011114] text-[30px] font-[700] workSans mt-3">Identity verification</p>
      <p className="text-[#011114] text-[16px] font-[400] workSans">
        Ensure that your file size does not exceed the 50KB limit.
      </p>
      <p className="mb-4 text-[#011114] text-[13px] font-[400] workSans">
      file types: PNG, JPEG, JPG, and BMP files.
      </p>
      {imagesPreview.length === 0 ? (
        <div
          className={`bg-[#E20BE308] ${isDraggingOver ? 'bg-[#e30be355]' : ''} md:w-[464px] w-full h-[316px] flex flex-col justify-center items-center`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div>
            {
              isLoading ? (
                <div>
                  <p className="signIn text-[16px] pb-4 font-[400] workSans">Processing File...</p>
                </div>
              ) : (<></>)
            }
          </div>
          <div className="cursor-pointer" onClick={handleDivClick}>
            <img src={uploadIcon} className="m-auto" alt="uploadIcon" />
            <p className="signIn text-[16px] pb-4 font-[400] workSans">Upload your ID</p>
          </div>
          <input
            type="file"
            id="ctl"
            accept=".png, .jpeg, .jpg, .bmp"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </div>
      ) : (
        <div>
          <img className="md:w-[464px] w-full h-[316px]" src={imagesPreview[0]} alt="document" />
        </div>
      )}
      <div className="flex justify-between gap-6 items-center my-4">
        <button
          type="button"
          onClick={handleSubmit}
          className="linearGradient rounded-[32px] w-full text-[14px] font-[500] workSans text-[#fff]"
        >
          {isSendingFile ? "Loading..." : "Verify"}
        </button>
        <button
          type="button"
          onClick={handleBack}
          className="border-[2px] signIn custom_button border-[#E20BE3] rounded-[32px] w-full text-[14px] font-[500] workSans text-[#fff]"
        >
          Go back
        </button>
      </div>
    </div>
  );
};

export default UploadID;
