import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResources } from '../../redux/resources/resources';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchWithSession } from '../../redux/session';

const ModuleContent = () => {
  const { resourceId, courseId, moduleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = localStorage.getItem('userId');
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const [completed, setCompleted] = useState({});

  const { resources, loadingResources, resourcesError } = useSelector((state) => state.getResources);
  const resource = resources?.find((resource) => resource?.schooltitle.toLowerCase().replace(/\s+/g, '-') === resourceId);
  const course = resource?.schoolcourses.find((course) => course?.coursetitle.toLowerCase().replace(/\s+/g, '-') === courseId);
  const module = course?.coursemodules.find((module) => module?.moduletitle.toLowerCase().replace(/\s+/g, '-') === moduleId);

  // Index of the current module
  const currentModuleIndex = course?.coursemodules.findIndex((module) => module?.moduletitle.toLowerCase().replace(/\s+/g, '-') === moduleId);

  // Determining the previous and next modules
  const previousModule = currentModuleIndex > 0 ? course?.coursemodules[currentModuleIndex - 1] : null;
  const nextModule = currentModuleIndex < course?.coursemodules.length - 1 ? course?.coursemodules[currentModuleIndex + 1] : null;

  useEffect(() => {
    dispatch(fetchResources());
  }, [dispatch]);

  const handleMarkComplete = async (moduleContentId, isCompleted) => {
    try {
      const response = await fetchWithSession(`${api_url}/api/auth/markModuleContentComplete/${userId}`, {
        method: 'PUT',
        body: {
          moduleContentId: moduleContentId,
          completed: isCompleted,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setCompleted((prevState) => ({
        ...prevState,
        [moduleContentId]: isCompleted,
      }));

      toast.success('Progess saved', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error('Error saving progress, please retry', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div className="px-[4%] pb-6">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {loadingResources && <p className="text-[#616161] text-[16px] font-[500] workSans">Loading course...</p>}
      {resourcesError && <p className="text-[#616161] text-[16px] font-[500] workSans">Error loading course</p>}
      {resource && (
        <div>
          <h1 className="text-[#011114] text-[40px] font-[400] baskerville text-center py-4">{module.moduletitle}</h1>
          <img src={module.moduleimg} alt="course_img" className="w-full md:w-[1280px] md:h-[300px] rounded-[16px]" />
          <p className="font-[700] text-[22px] my-4 workSans text-[#011114]">{module.moduledesc}</p>
          {module?.modulecontent?.map((item, index) => (
            <div className="my-4" key={index}>
              <p className="text-[#011114] text-[16px] font-[400] workSans">
                {item.modulecontenttext}
              </p>
              {item.modulecontentimage ? (<img src={item.modulecontentimage} alt="course_img" className="w-full md:w-[400px] rounded-[16px] my-4" />) : (<></>)}
            </div>
          ))}
          <div className="bg-[#5900641A] py-[16px] px-[40px] rounded-[8px] w-fit my-8">
            <p className="text-center workSans font-[500] text-[20px] text-[#011114]">Track progress</p>
            <p className="text-center workSans font-[400] text-[15px] text-[#011114]">Did you complete this lesson?</p>
            <div className="flex gap-4 justify-center mt-4">
              <button
                onClick={() => handleMarkComplete(module._id, true)}
                disabled={completed[module._id] === true}
                className="bg-[#7C067D] text-white px-2 py-[1px] rounded-[4px]"
              >
                Yes
              </button>
              <button
                onClick={() => handleMarkComplete(module._id, false)}
                disabled={completed[module._id] === false}
                className="bg-[#995C00] text-white px-2 py-[1px] rounded-[4px]"
              >
                No
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 mt-[4%]">
            {previousModule && (
              <div>
                <p className="font-[700] text-[22px] mt-[3%] mb-4 workSans text-[#011114]">Previous Module</p>
                <div className="bg-[#5900641A] p-[40px] rounded-[16px]">
                  <img src={previousModule.moduleimg} className="w-full md:w-[400px] h-[300px]" alt="course_img" />
                  <div className="md:w-[350px]">
                    <p className="text-[#011114] text-[22px] font-[700] workSans pt-4">{previousModule.moduletitle}</p>
                    <p className="text-[#011114] text-[18px] font-[400] workSans">{previousModule.moduledesc}</p>
                  </div>
                  <button
                    type="button"
                    className="linearGradient mt-4 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
                    onClick={() => navigate(`/resources/school/${resource.schooltitle.toLowerCase().replace(/\s+/g, '-')}/course/${course.coursetitle.toLowerCase().replace(/\s+/g, '-')}/module/${previousModule.moduletitle.toLowerCase().replace(/\s+/g, '-')}`)}
                  >
                    Start Learning
                  </button>
                </div>
              </div>
            )}
            {nextModule && (
              <div>
                <p className={`font-[700] text-[22px] mt-[3%] ${previousModule ? "text-end" : "text-start"} mb-4 workSans text-[#011114]`}>Next Module</p>
                <div className="bg-[#5900641A] p-[40px] rounded-[16px]">
                  <img src={nextModule.moduleimg} className="w-full md:w-[400px] h-[300px]" alt="course_img" />
                  <div className="lg:w-[350px]">
                    <p className="text-[#011114] text-[22px] font-[700] workSans pt-4">{nextModule.moduletitle}</p>
                    <p className="text-[#011114] text-[18px] font-[400] workSans">{nextModule.moduledesc}</p>
                  </div>
                  <button
                    type="button"
                    className="linearGradient mt-4 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
                    onClick={() => navigate(`/resources/school/${resource.schooltitle.toLowerCase().replace(/\s+/g, '-')}/course/${course.coursetitle.toLowerCase().replace(/\s+/g, '-')}/module/${nextModule.moduletitle.toLowerCase().replace(/\s+/g, '-')}`)}
                  >
                    Start Learning
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModuleContent;
