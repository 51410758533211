import React from 'react';
import { useParams } from 'react-router-dom';

const Tether = () => {
    const { userId } = useParams();
  return (
    <div>
      Tether
    </div>
  );
};

export default Tether;
