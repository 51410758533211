import React, { useState } from 'react';
import logo from "../../images/logoblack.png";
import { useDispatch } from 'react-redux';
import { verifyOtp } from '../../../redux/auth/verifyOtp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const VerifyEmail = ({ setPage, email }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const verifyEmailOtp = (e) => {
    e.preventDefault();

    setLoading(true);
    const otpData = {
      email, otp
    };

    dispatch(verifyOtp(otpData)).then((res) => {
      if (res.error) {
        toast.error(res?.error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      } else {
        toast.success(res?.payload, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
        setTimeout(() => {
          setPage(3);
        }, 1000)
      }
    });
  }

  const areFieldsEmpty = () => {
    return !otp;
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex justify-between gap-2 items-center">
        <Link to="/"><img src={logo} alt="logo" /></Link>
        <button
          onClick={() => setPage(1)}
          type="button"
          className={`px-2 py-[4px] border-[#616161] text-[#011114] border-[1px] bg-[#fff] rounded-[50%]`}
        >
          &larr;
        </button>
      </div>
      <p className="text-[#011114] text-[30px] font-[700] workSans my-3">Sign up</p>
      <p className="text-[#011114] text-[16px] pb-4 font-[400] workSans">
        An OTP code was sent to <span className="heading">{email}</span>,
        Expires in 10 minutes
      </p>
      <div className="mb-4">
        <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="otp">
          Enter OTP
        </label>
        <input
          className="appearance-none border-[#D9D9D9] border-[1px] rounded-[8px] w-full md:w-[400px] py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none"
          id="otp"
          type="number"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          placeholder="123456"
        />
      </div>
      <button
        type="button"
        onClick={verifyEmailOtp}
        className={`linearGradient rounded-[32px] w-full text-[14px] font-[500] workSans text-[#fff] ${areFieldsEmpty() ? 'cursor-not-allowed' : ''}`}
        disabled={areFieldsEmpty()}
      >
        {loading ? "loading..." : "Next"}
      </button>
    </div>
  );
};

export default VerifyEmail;
