import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Homepage from "./pages/homepage/Homepage";
import Signin from './components/auth/Signin';
import Signup from './components/auth/Signup';
import Payment from './components/auth/Payment';
import Donate from './pages/Donate';
import UserRoutes from './protectedRoutes/UserRoutes';
import PublicRoutes from './protectedRoutes/PublicRoutes';
import UserDashboard from './pages/userDashboard/UserDashboard';
import Events from './pages/events/Events';
import EventDetails from './pages/events/EventDetails';
import Resources from './pages/resources/Resources';
import ResourceSchools from './pages/resources/ResourceSchools';
import Courses from './pages/resources/Courses';
import ModuleContent from './pages/resources/ModuleContent';
import Webinar from './pages/webinars/Webinar';
import WebinarDetails from './pages/webinars/WebinarDetails';
import LiveSession from './pages/liveSession/LiveSession';
import LiveEvent from './pages/liveSession/LiveEvent';
import Merch from './pages/shop/Merch';
import Order from './pages/shop/Order';
import Checkout from './pages/shop/Checkout';
import Cart from './pages/shop/Cart';
import OrderDetails from './pages/shop/orderStatus/OrderDetails';
import Contact from './pages/userDashboard/Contact';
import Profile from './pages/userDashboard/Profile';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<Homepage />} />
          <Route path="/auth/signin" element={<Signin />} />
          <Route path="/auth/signup" element={<Signup />} />
          <Route path={`/auth/payment/:userId`} element={<Payment />} />
          <Route path="/donate" element={<Donate />} />
        </Route>
      </Routes>

      {/* Users routes */}
      <Routes>
        <Route element={<UserRoutes />}>
          <Route path="/" element={<UserDashboard />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:eventId" element={<EventDetails />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/resources/school/:resourceId" element={<ResourceSchools />} />
          <Route path="/resources/school/:resourceId/course/:courseId" element={<Courses />} />
          <Route path="/resources/school/:resourceId/course/:courseId/module/:moduleId" element={<ModuleContent />} />
          <Route path="/video_courses" element={<Webinar />} />
          <Route path="/video_course/:videoCourseId" element={<WebinarDetails />} />
          <Route path="/live_session/:callID" element={<LiveSession />} />
          <Route path="/live_session" element={<LiveEvent />} />
          <Route path="/merch" element={<Merch />} />
          <Route path="/orders" element={<Order />} />
          <Route path="/checkout/summary" element={<Checkout />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/order/:orderId" element={<OrderDetails />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
