import React, { useState } from 'react';
import logo from "../../images/logoblack.png";
import { useDispatch } from 'react-redux';
import { sendOtp } from '../../../redux/auth/sendOtp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const Email = ({ setPage, email, setEmail }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const verifyEmail = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      toast.warning("Please add a valid email", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setLoading(true);
    dispatch(sendOtp(email)).then((res) => {
      if (res.error) {
        toast.error(res.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      } else {
        toast.success("Otp sent", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
        setTimeout(() => {
          setPage(2);
        }, 1000)
      }
    });
  }

  const areFieldsEmpty = () => {
    return !email;
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex justify-between gap-2 items-center">
        <Link to="/"><img src={logo} alt="logo" /></Link>
        <button
          onClick={() => setPage(0)}
          type="button"
          className={`px-2 py-[4px] border-[#616161] text-[#011114] border-[1px] bg-[#fff] rounded-[50%]`}
        >
          &larr;
        </button>
      </div>
      <p className="text-[#011114] text-[30px] font-[700] workSans my-3">Sign up</p>
      <p className="text-[#011114] text-[16px] pb-4 font-[400] workSans">
        Verify your email address
      </p>
      <div className="mb-4">
        <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="email">
          Email address
        </label>
        <input
          className="appearance-none border-[#D9D9D9] border-[1px] rounded-[8px] w-full md:w-[400px] py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none"
          id="email"
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="mmajukaris@gmail.com"
        />
      </div>
      <button
        type="button"
        onClick={verifyEmail}
        className={`linearGradient rounded-[32px] w-full text-[14px] font-[500] workSans text-[#fff] ${areFieldsEmpty() ? 'cursor-not-allowed' : ''}`}
        disabled={areFieldsEmpty()}
      >
        {loading ? "loading..." : "Next"}
      </button>
    </div>
  );
};

export default Email;
