import React, { useState } from 'react';
import { PaystackButton } from 'react-paystack'
import { useNavigate } from 'react-router-dom';

const Paystack = ({ setViewPaymentModal, setPage }) => {
  const publicKey = "pk_test_043a090c91a3279a4eaff9f6efc3fcd274ada0a7";
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [name, setName] = useState("");
  const desc = "Donation to Pipsnpearl"
  const navigate = useNavigate();

  const paymentSuccess = async () => {
    setViewPaymentModal(true);
    setTimeout(() => {
      setViewPaymentModal(false);
      navigate("/");
    }, 2000);
  };

  const componentProps = {
    email,
    amount: amount * 100,
    metadata: {
      name,
      desc
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => paymentSuccess(),
    onClose: () => alert("Payment closed"),
  };

  const style = {
    input: "appearance-none border-[#D9D9D9] border-[1px] my-2 rounded-[8px] w-full py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none",
    button: "linearGradient rounded-[32px] w-full text-[14px] font-[500] workSans text-[#fff]"
  }

  return (
    <div className="">
      <button
        onClick={() => setPage(0)}
        type="button"
        className={`px-2 py-[4px] border-[#616161] text-[#011114] border-[1px] bg-[#fff] rounded-[50%]`}
      >
        &larr;
      </button>
      <h1 className="text-center text-[25px] my-4 font-[600] workSans">Make your payment here</h1>
      <div className="max-w-md mx-auto my-4">
        <input type="email" placeholder="Email" className={style.input} value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="text" placeholder="Name" className={style.input} value={name} onChange={(e) => setName(e.target.value)} />
        <input type="number" placeholder="Amount" className={style.input} value={amount} onChange={(e) => setAmount(e.target.value)} />
        <PaystackButton className={style.button} {...componentProps} />
      </div>
    </div>
  );
};

export default Paystack;
