import React, { useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import "./StripePaymentForm.css";

const StripePaymentForm = ({ setViewPaymentModal, setPage }) => {
  const stripe = useStripe();
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const elements = useElements();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setPaymentProcessing(true);

    const cardNumberElement = elements.getElement(CardNumberElement);
    const amount = 1000; // Amount in cents

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
      });

      if (error) {
        toast.error(error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setPaymentProcessing(false);
        return;
      }

      let response;
      try {
        response = await axios.post(`${api_url}/api/auth/payment-intent/${userId}`, {
          amount,
        });
      } catch (apiError) {
        toast.error(apiError?.response?.data?.message || "Payment initiation failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setPaymentProcessing(false);
        return;
      }

      const { clientSecret } = response.data;

      const confirmResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.id,
      });

      if (confirmResult.error) {
        toast.error(confirmResult.error?.message || "Payment confirmation failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setPaymentProcessing(false);
      } else {
        setViewPaymentModal(true);
        setPaymentProcessing(false);
        setTimeout(() => {
          navigate("/auth/signin");
          setViewPaymentModal(false);
        }, 2000);
      }
    } catch (error) {
      toast.error("An unexpected error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setPaymentProcessing(false);
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <div className="md:w-[450px] w-full">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <form onSubmit={handleSubmit}>
        <div className="flex justify-between gap-2 items-center">
          <button
            onClick={() => setPage(0)}
            type="button"
            className={`px-2 py-[4px] border-[#616161] text-[#011114] border-[1px] bg-[#fff] rounded-[50%]`}
          >
            &larr;
          </button>
          <p className="text-[16px] my-4 font-[400] workSans">Make your payment here</p>
        </div>
        <div className="mb-4">
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="cardNumber">Card Number</label>
          <CardNumberElement id="cardNumber" options={cardElementOptions} className="w-full p-2 border rounded" />
        </div>
        <div className="mb-4">
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="cardExpiry">Expiry Date</label>
          <CardExpiryElement id="cardExpiry" options={cardElementOptions} className="w-full p-2 border rounded" />
        </div>
        <div className="mb-4">
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="cardCvc">CVC</label>
          <CardCvcElement id="cardCvc" options={cardElementOptions} className="w-full p-2 border rounded" />
        </div>
        <button className={`linearGradient rounded-[32px] w-full text-[14px] font-[500] workSans text-[#fff]`} type="submit" disabled={!stripe || paymentProcessing}>
          {paymentProcessing ? "Processing payment..." : "Pay"}
        </button>
      </form>
    </div>
  );
};

export default StripePaymentForm;
