import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchEvents } from '../../redux/events/events';
import { fetchUser } from '../../redux/auth/getUser';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchWithSession } from '../../redux/session';

const EventDetails = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const { events, loadingEvents, eventsError } = useSelector((state) => state.getEvents);
  const { user } = useSelector((state) => state.getUser);
  const userId = localStorage.getItem('userId');
  const event = events?.find((event) => event._id === eventId);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetchWithSession(`${api_url}/api/register/${eventId}`, {
        method: 'POST',
        body: {
          name: formData.name,
          email: user.email || "noemail@gmail.com",
        },
      });

      if (response.status === 201) {
        toast.success("Registration successful", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setTimeout(() => {
        navigate("/events");
        window.location.reload();
      }, 3000)
    } catch (err) {
      // Handle error
      toast.error(`Registration failed: ${err?.response?.data?.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(fetchEvents());
    dispatch(fetchUser(userId));
  }, [dispatch, userId])

  useEffect(() => {
    if (events?.length > 0) {
      if (!eventId || !events.some(event => event._id === eventId)) {
        navigate("/events");
      }
    }
  }, [eventId, events, navigate]);

  return (
    <div className="py-[2%] px-[4%]">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <h1 className="baskerville font-[400] text-center text-[20px] md:text-[40px] pb-4 text-[#011114]">
        Register for this <span className="heading">Event</span>
      </h1>
      {loadingEvents && <p className="text-[#616161] text-[16px] font-[500] workSans">Loading event...</p>}
      {eventsError && <p className="text-[#616161] text-[16px] font-[500] workSans">Error loading event</p>}
      {event && (
        <div>
          <img
            src={event.eventimage}
            alt="Event"
            className="w-full h-[300px] rounded-[8px]"
          />
          <h2 className="font-[600] text-[32px] pt-4 workSans text-[#011114]">{event.sessionname}</h2>
          <p className="font-[400] text-[16px] workSans text-[#011114]">{event.sessiondetails}</p>
          <p className="text-[#011114] font-[700] text-[14px] workSans">{event.sessiontime}</p>
          <div className="max-w-sm my-4 w-full bg-[#E20BE308] rounded-[32px] p-6 shadow-lg">
            <form className="mt-6" onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  className="appearance-none border-[#D9D9D9] border-[1px] rounded-[8px] w-full py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="email">
                  Email
                </label>
                {user && (
                  <input
                    className="appearance-none border-[#D9D9D9] border-[1px] rounded-[8px] w-full py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none"
                    type="email"
                    id="email"
                    placeholder="Your email"
                    readOnly
                    value={user.email}
                    onChange={handleChange}
                    required
                  />
                )}
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="linearGradient w-full rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
              >
                {isSubmitting ? 'Registering...' : 'Register'}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetails;
