import React, { useState } from 'react';
import { PaystackButton } from 'react-paystack'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Paystack = ({ setPage }) => {
  const publicKey = "pk_test_043a090c91a3279a4eaff9f6efc3fcd274ada0a7";
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const { userId } = useParams();
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(10000);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const paymentSuccess = async () => {
    try {
      const response = await axios.post(`${api_url}/api/auth/paystack-payment-intent/${userId}`, {
        amount: amount,
      });
      setTimeout(() => {
        navigate("/auth/signin");
      }, 1000);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      throw error;
    }
  };
  const handleSuccess = async () => {
    try {
      await paymentSuccess();
      toast.success('Payment Successful', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error('Payment verification failed', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };


  const componentProps = {
    email,
    amount: amount * 100,
    metadata: {
      name,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => handleSuccess(),
    onClose: () => alert("Payment closed"),
  };

  const style = {
    input: "appearance-none border-[#D9D9D9] border-[1px] my-2 rounded-[8px] w-full py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none",
    button: "linearGradient rounded-[32px] w-full text-[14px] font-[500] workSans text-[#fff]"
  }

  return (
    <div className="md:w-[500px]">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex justify-between gap-2 items-center">
        <button
          onClick={() => setPage(0)}
          type="button"
          className={`px-2 py-[4px] border-[#616161] text-[#011114] border-[1px] bg-[#fff] rounded-[50%]`}
        >
          &larr;
        </button>
        <h1 className="text-[15px] md:text-[25px] pl-7 my-4 font-[400] workSans">Make your payment here</h1>
      </div>
      <div className="max-w-md mx-auto my-4">
        <input type="email" placeholder="Email" className={style.input} value={email} onChange={(e) => setEmail(e.target.value)} />
        <input type="text" placeholder="Name" className={style.input} value={name} onChange={(e) => setName(e.target.value)} />
        <input type="number" readOnly placeholder="Amount" className={style.input} value={amount} onChange={(e) => setAmount(e.target.value)} />
        <PaystackButton className={style.button} {...componentProps} />
      </div>
    </div>
  );
};

export default Paystack;
