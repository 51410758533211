import React from 'react';
import { Link } from 'react-router-dom';
import logo from "./images/logowhite.png";
import location from "./images/Vector.png";
import phone from "./images/Vector (1).png";

const Footer = () => {
  const style = {
    list: "text-[#fff] pb-2 text-[12px] workSans font-[400]"
  }

  const footerContacts = [
    {
      icon: location,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    },
    {
      icon: phone,
      text: "Mobile number",
    }
  ]
  return (
    <div className="flex justify-center items-start gap-[5%] py-[5%]">
      <div className="md:w-[30%]">
        <img src={logo} alt="logo" className="my-6" />
        <p className="workSans text-[12px] font-[400] text-white">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
        </p>
      </div>
      <div>
        <p className="font-[700] text-[22px] my-6 workSans text-[#fff]">Company</p>
        <ul>
          <Link to="/auth/signin"><li className={style.list}>Live sessions</li></Link>
          <Link to="/auth/signin"><li className={style.list}>Resources</li></Link>
          <Link to="/auth/signin"><li className={style.list}>Upcoming events</li></Link>
          <Link to="/"><li className={style.list}>Contact us</li></Link>
        </ul>
      </div>
      <div className="md:w-[30%]">
        <p className="font-[700] text-[22px] my-6 workSans text-[#fff]">Contact Us</p>
        <div>
          {footerContacts.map((_, index) => (
            <div key={index} className="flex items-start gap-4 pb-4">
              <img src={_.icon} className="w-[15px]" alt={_.text} />
              <p className="text-[#fff] text-[12px] workSans font-[400]">{_.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;