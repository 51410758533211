import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMerch } from '../../redux/merch/merch';
import { fetchCart } from '../../redux/merch/fetchCart';
import { fetchWithSession } from '../../redux/session';
import { Link, useNavigate } from 'react-router-dom';
import { IoCartOutline } from "react-icons/io5";

const Merch = () => {
  const { merch, loadingMerch, merchError } = useSelector((state) => state.getMerch);
  const { cart } = useSelector((state) => state.getCart);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState({});
  const [activeProduct, setActiveProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState("");
  const [price, setPrice] = useState(0);
  const [productId, setProductId] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (userId) {
      dispatch(fetchCart(userId));
    }
  }, [dispatch, userId]);

  const totalQuantityProduct = cart?.cart?.items?.reduce((product, item) => product + item.quantity, 0);

  const updateQuantity = (productId, change) => {
    setActiveProduct(productId);
    setQuantity((prevQuantities) => ({
      ...prevQuantities,
      [productId]: Math.max((prevQuantities[productId] || 0) + change, 0),
    }));
  };

  const handleCartUpdate = async () => {
    if (!productId) {
      alert("Please set a quantity.");
      return;
    }

    if (!quantity[activeProduct]) {
      alert("Quantity is not set. Please set a quantity.");
      return;
    }

    const url = `${api_url}/api/add-to-cart`;

    const options = {
      method: 'POST',
      body: {
        userId,
        productId,
        selectedSize,
        quantity: quantity[activeProduct],
        price
      },
    };

    try {
      const response = await fetchWithSession(url, options);
      if (response.status === 200) {
        dispatch(fetchCart(userId));
        alert('Product added to cart successfully');
        setQuantity((prev) => ({ ...prev, [activeProduct]: 0 }));
        setSelectedSize("");
        setPrice(0);
      } else {
        alert('Failed to add product to cart');
        setQuantity((prev) => ({ ...prev, [activeProduct]: 0 }));
        setSelectedSize("");
        setPrice(0);
      }
    } catch (error) {
      setQuantity((prev) => ({ ...prev, [activeProduct]: 0 }));
      setSelectedSize("");
      setPrice(0);
      alert('Error occurred while adding product to cart');
    }
  };

  const increaseQuantity = (productId, price) => {
    setProductId(productId);
    setPrice(price);
    updateQuantity(productId, 1);
  }

  const decreaseQuantity = (productId, price) => {
    setProductId(productId);
    setPrice(price);
    updateQuantity(productId, -1);
  }

  const increaseSelectedSizeQuantity = (sizeId, size, productId, price) => {
    updateQuantity(sizeId, 1);
    setSelectedSize(size);
    setProductId(productId);
    setPrice(price);
  };

  const decreaseSelectedSizeQuantity = (sizeId, size, productId, price) => {
    updateQuantity(sizeId, -1);
    setSelectedSize(size);
    setProductId(productId);
    setPrice(price);
  };

  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  useEffect(() => {
    dispatch(fetchMerch());
  }, [dispatch]);

  const openCartModal = (product) => {
    setIsPopupOpen(!isPopupOpen)
    setProduct(product)
  }

  const closeCartModal = () => {
    setIsPopupOpen(false)
    setProduct(null)
  }

  return (
    <div>
      <section className="px-[4%] pb-[4%]">
        <div className="flex items-center justify-between gap-4 top-0 sticky bg-[#fff]">
          <div className="py-6">
            <h1 className="text-[#121212] text-[28px] font-[700] workSans">Shop Merch</h1>
            <p className="text-[#121212] text-[18px] font-[400] workSans">Shop the very best from our e-mart</p>
          </div>
          <Link to="/cart">
            <div className="flex items-center gap-2 hover:text-[#E20BE3]">
              <div className="flex items-start">
                <IoCartOutline className="font-[600] text-[30px]" />
                {cart?.cart?.items?.length > 0 && (
                  <div className="bg-[#E20BE3] rounded-[50%] ml-[-10px] text-[9px] font-[600] text-[#fff] px-[4px] py-[1px]">{totalQuantityProduct}</div>
                )}
              </div>
              <p className="text-[18px] font-[400] workSans">Cart</p>
            </div>
          </Link>
        </div>
        {loadingMerch && (
          <p className="font-[400] text-[20px] workSans pt-5 pb-3 text-[#011114]">Loading products...</p>
        )}
        {merchError && (
          <p className="font-[400] text-[20px] workSans pt-5 pb-3 text-[#011114]">
            Error fetching products. Please check your internet connection and try refreshing the page.
          </p>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {merch && merch.length > 0 ? (
            merch.map((product) => (
              <div className="bg-[#5900641A] rounded-[24px] p-[24px]" key={product._id}>
                <img
                  className="w-full h-[250px] custom-product"
                  src={product.productimage}
                  alt={product.productname}
                />
                <div className="">
                  <p className="font-[600] text-[18px] workSans pt-5 pb-3 text-[#011114]">{limitWords(product.productname, 4)}</p>
                  {product.productSizes.length > 0 && (
                    <div className="flex justify-between items-center gap-2">
                      <p className="text-[#011114] font-[400] text-[16px] workSans">Variations</p>
                      <div className="flex gap-4">
                        {product.productSizes.map((size, index) => (
                          <p key={index} className="font-[700] text-[14px] text-[#011114] workSans">{size.size}</p>
                        ))}
                      </div>
                    </div>
                  )}
                  {product.productSizes.length < 1 && (
                    <div>
                      <p className="text-[#011114] font-[400] text-[12px] workSans">{product.quantity} units left</p>
                      <div className={`flex justify-between ${product.productSizes.length > 0 ? 'mt-4' : ''} items-center gap-2`}>
                        <p className="text-[#011114] font-[400] text-[16px] workSans">Quantity</p>
                        <div className="flex gap-2">
                          <button onClick={() => increaseQuantity(product._id, product.price)} type="button" className="bg-[#E20BE3] rounded-[4px] px-[6px] font-[700] text-[14px] text-[#fff] workSans">+</button>
                          <div className="border-[1px] border-[#D9D9D9] rounded-[4px] px-[6px] font-[700] text-[14px] text-[#011114] workSans">{quantity[product._id] || 0}</div>
                          <button onClick={() => decreaseQuantity(product._id, product.price)} type="button" className="bg-[#E20BE3] rounded-[4px] px-[6px] font-[700] text-[14px] text-[#fff] workSans">-</button>
                        </div>
                      </div>
                    </div>
                  )}
                  <p className="font-[700] text-[24px] text-[#011114] mb-4 workSans">$ {product.price}</p>
                </div>
                <button
                  type="button"
                  onClick={product.productSizes.length > 0 ? () => openCartModal(product) : handleCartUpdate}
                  className="bg-[#011114] rounded-[32px] py-[10px] px-[90px] font-[600] text-[14px] text-[#fff] w-full workSans"
                >
                  Add to cart
                </button>
              </div>
            ))
          ) : (
            <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingMerch ? "" : "No products found"}</div>
          )}
        </div>
      </section>
      {isPopupOpen && product && (
        <div className="fixed inset-0 flex items-center p-4 justify-center bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg md:w-full">
            <div className="flex flex-col">
              <div className="flex justify-between items-center gap-4 my-4">
                <p className="text-[#616161] text-[20px] font-[500] workSans">Please select a variation</p>
                <button
                  type="button"
                  onClick={closeCartModal}
                  className="font-[500] text-[20px] text-[#011114] workSans"
                >X</button>
              </div>
              {product.productSizes.map((size, index) => (
                <div key={index} className="flex justify-between my-4 items-center gap-4">
                  <div>
                    <p className="font-[600] text-[18px] workSans text-[#011114]">{size.size}</p>
                    <p className="text-[#011114] font-[400] text-[12px] workSans">{size.quantity} units left</p>
                    <p className="font-[400] text-[18px] workSans text-[#011114]">${product.price}</p>
                  </div>
                  <div>
                    <div className="flex gap-2">
                      <button onClick={() => increaseSelectedSizeQuantity(size._id, size.size, product._id, product.price)} type="button" className="bg-[#E20BE3] rounded-[4px] px-[10px] py-[3px] font-[700] text-[14px] text-[#fff] workSans">+</button>
                      <div className="border-[1px] border-[#D9D9D9] rounded-[4px] px-[10px] py-[3px] font-[700] text-[14px] text-[#011114] workSans">{quantity[size._id] || 0}</div>
                      <button onClick={() => decreaseSelectedSizeQuantity(size._id, size.size, product._id, product.price)} type="button" className="bg-[#E20BE3] rounded-[4px] px-[10px] py-[3px] font-[700] text-[14px] text-[#fff] workSans">-</button>
                    </div>
                    <button
                      type="button"
                      onClick={handleCartUpdate}
                      className="bg-[#011114] rounded-[5px] mt-[5px] px-[4px] py-[2px] font-[600] text-[14px] text-[#fff] workSans"
                    >
                      Add to cart
                    </button>
                  </div>
                </div>
              ))}
              <div className="flex justify-between items-center gap-4">
                <button
                  type="button"
                  onClick={closeCartModal}
                  className="border-[#E20BE3] px-[5px] py-[16px] border-[1px] rounded-[5px] w-full text-[14px] font-[500] workSans text-[#011114]"
                >
                  CONTINUE YOUR SHOPPING
                </button>
                <button
                  type="button"
                  onClick={() => navigate("/cart")}
                  className="linearGradient2 rounded-[5px] px-[5px] py-[16px] w-full text-[14px] font-[500] workSans text-[#fff]"
                >
                  VIEW CART AND CHECKOUT
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Merch
