import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithSession } from '../session';

const FETCH_ORDERS = "FETCH_ORDERS";
const api_url = process.env.REACT_APP_BASE_API_URL;
const FETCH_ORDERS_URL = `${api_url}/api/orders`;

const initialState = {
  orders: [],
  loadingOrders: false,
  ordersError: false,
}

export const fetchOrders = createAsyncThunk(
  FETCH_ORDERS,
  async (userId, { rejectWithValue }) => {
    try {
      const response = await fetchWithSession(`${FETCH_ORDERS_URL}/${userId}`, {
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.error.message);
    }
  }
);

const getOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ORDERS}/pending`:
      return {
        ...state,
        loadingOrders: true,
        ordersError: false
      }
    case `${FETCH_ORDERS}/fulfilled`:
      return {
        orders: action.payload,
        loadingOrders: false,
        ordersError: false
      }
    case `${FETCH_ORDERS}/rejected`:
      return {
        ...state,
        loadingOrders: false,
        ordersError: true
      }
    default:
      return { ...state, loadingOrders: false, ordersError: false };
  }
}

export default getOrdersReducer;
