import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents } from '../../redux/events/events';
import { useParams, useNavigate } from 'react-router-dom';
import { StreamCall, StreamVideo, StreamVideoClient, LivestreamLayout } from "@stream-io/video-react-sdk";
import '@stream-io/video-react-sdk/dist/css/styles.css';
import { Chat, Channel, ChannelHeader, MessageList, MessageInput, Window } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
import 'stream-chat-react/dist/css/v2/index.css';
import { jwtDecode } from 'jwt-decode';


const LiveSession = () => {
  const { callID } = useParams();
  const navigate = useNavigate();
  const { events, loadingEvents, eventsError } = useSelector((state) => state.getEvents);
  const event = events?.find((event) => event._id === callID);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEvents())
  }, [dispatch])

  useEffect(() => {
    if (events?.length > 0) {
      if (!callID || !events.some(event => event._id === callID)) {
        navigate("/live_session");
      }
    }
  }, [callID, events, navigate]);
  
  const apiKey = "9xwu9sct59s7";
  const callId = callID;
  const [channel, setChannel] = useState(null);
  const [client, setClient] = useState(null);
  const [call, setCall] = useState(null);
  const [chatClient, setChatClient] = useState(null);
  const [isCallLive, setIsCallLive] = useState(false);

  useEffect(() => {
    const initializeStream = async () => {
      const userToken = localStorage.getItem("streamToken");
      if (!userToken) {
        console.error("No user token found.");
        return;
      }

      try {
        // Decode the token to extract the userId
        const decodedToken = jwtDecode(userToken);
        const userId = decodedToken.user_id;

        const user = {
          id: userId,
          name: "Oliver-Viewer",
          image: "https://getstream.io/random_svg/?id=oliver&name=Oliver-Viewer"
        };

        const streamClient = new StreamVideoClient({ apiKey, user, token: userToken });
        setClient(streamClient);

        const streamCall = streamClient.call('livestream', callId);
        streamCall.camera.disable();
        streamCall.microphone.disable();
        streamCall.join()
          .then(() => {
            setIsCallLive(streamCall.state.isLive);
          })
          .catch((error) => {
            console.error("Error joining the call:", error);
          });
        setCall(streamCall);

        const streamChatClient = StreamChat.getInstance(apiKey);
        await streamChatClient.connectUser(user, userToken)
          .then(() => {
            const streamChannel = streamChatClient.channel('livestream', callId, {
              name: 'Livestream Chat',
              members: [userId],
            });

            streamChannel.watch();
            setChannel(streamChannel);
          })
          .catch((error) => {
            console.error("Error connecting user:", error);
          });

        setChatClient(streamChatClient);

        return () => {
          // Cleanup: Disconnect chat client when component unmounts
          if (streamChatClient) {
            streamChatClient.disconnectUser();
          }
        };
      } catch (error) {
        console.error("Error decoding token or connecting user:", error);
      }
    };

    initializeStream();
  }, [apiKey, callId]);

  if (!client || !call || !chatClient || !channel) {
    return <div  className="font-[600] text-[15px] p-4 workSans text-[#011114]">Loading...</div>;
  }

  return (
    <div className="p-[4%]">
      <div className="md:flex items-start justify-between gap-4">
        <StreamVideo client={client}>
          <StreamCall call={call}>
            <LivestreamLayout
              showParticipantCount={true}
              showDuration={true}
              showLiveBadge={true}
            />
          </StreamCall>
        </StreamVideo>
        <Chat client={chatClient} theme="livestream dark">
          <Channel channel={channel}>
            <Window>
              <ChannelHeader />
              <MessageList />
              <MessageInput />
            </Window>
          </Channel>
        </Chat>
      </div>
    </div>
  );
};
export default LiveSession;
