// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAa8dYOa9pgNBVqH_h0aF961qbvGskLEAI",
  authDomain: "pipsnpearl.firebaseapp.com",
  projectId: "pipsnpearl",
  storageBucket: "pipsnpearl.appspot.com",
  messagingSenderId: "964543139398",
  appId: "1:964543139398:web:3be19bd9eea85356a34447",
  measurementId: "G-360HMEWJWY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export { auth, storage, signInAnonymously };
