import React from 'react'

const Pending = ({ pendingOrders }) => {
  return (
    <div>
      {pendingOrders && (
        <div>
          {
            pendingOrders?.map(order => (
              <div className="border-[1px] border-[#a2a2a7] rounded my-4 p-4">
                {order?.items?.[0] && (
                  <div className="flex gap-2 items-start">
                    <div key={order.items[0]._id}>
                      <img className="w-[104px]" src={order.items[0].productimage} alt={order.items[0].productname} />
                    </div>
                    <div>
                      <p className="text-[16px] workSans">{order.items[0].productname}</p>
                      <p className="text-[12px] workSans">order ID: {order._id}</p>
                      <p 
                      className={`px-[2px] py-[2px] w-fit rounded mt-6 text-[#fff] workSans text-[13px] bg-red-500`}
                      >
                        failed
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))
          }
        </div>
      )}
    </div>
  )
}

export default Pending
