// Counter.js
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const Counter = ({ targetNumber }) => {
  const [count, setCount] = useState(0);
  const { ref, inView } = useInView();

  useEffect(() => {
    let interval;
    if (inView) {
      let start = 0;
      const duration = 2000;
      const increment = targetNumber / (duration / 100);

      interval = setInterval(() => {
        start += increment;
        if (start >= targetNumber) {
          start = targetNumber;
          clearInterval(interval);
        }
        setCount(Math.ceil(start));
      }, 100);
    } else {
      setCount(0);
    }

    return () => clearInterval(interval);
  }, [inView, targetNumber]);

  return (
    <p ref={ref} className="text-[30px] font-[600] workSans text-black">
      {count}+
    </p>
  );
};

export default Counter;
