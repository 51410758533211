import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchResources } from '../../redux/resources/resources';
import "./style.css";

const Resources = () => {
  const { resources, loadingResources, resourcesError } = useSelector((state) => state.getResources);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const difficulties = [
    { name: 'Beginner' },
    { name: 'Intermediate' },
    { name: 'Advanced' },
  ];

  const [selectedDifficulty, setSelectedDifficulty] = useState(difficulties[0]?.name?.toLowerCase());

  useEffect(() => {
    dispatch(fetchResources());
  }, [dispatch]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category === 'All' ? '' : category);
  };

  const handleDifficultyClick = (difficulty) => {
    setSelectedDifficulty(difficulty)
  }

  const filteredResources = resources
    .filter((resource) =>
      resource.schooltitle.toLowerCase().includes(searchTerm.toLowerCase())
    ).filter((resource) =>
      selectedCategory ? resource.schoolcategory.toLowerCase() === selectedCategory.toLowerCase() : true
    ).filter((resource) =>
      selectedDifficulty ? resource.schooldifficulty.toLowerCase() === selectedDifficulty.toLowerCase() : true
    )

  const categories = ['Forex', 'Crypto', 'Tech', 'All'];

  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  return (
    <div>
      <section className="px-[4%]">
        <h1 className="text-[#121212] text-[28px] pt-6 font-[700] workSans">Resources</h1>
        <p className="text-[#121212] text-[18px] pb-6 font-[400] workSans">You can start by clicking on any of the difficulty levels below</p>
        <div className="flex flex-wrap items-center gap-6">
          {difficulties.map((difficulty) => (
            <div
              key={difficulty.name}
              onClick={() => handleDifficultyClick(difficulty.name.toLowerCase())}
              className={`hover:bg-[#58006434] ${selectedDifficulty === difficulty.name.toLowerCase() ? 'difficulty_bg' : 'bg-[#5900640D]'} cursor-pointer lg:w-[300px] rounded-[8px] py-[10px] px-[10px] md:py-[30px] md:px-[40px]`}
            >
              <p className={`workSans font-[600] text-[15px] md:text-[20px] text-center ${selectedDifficulty === difficulty.name.toLowerCase() ? 'text-[#FFFFFF]' : 'text-[#011114]'}`}>
                {difficulty.name}
              </p>
            </div>
          ))}
        </div>
        <input
          type="search"
          placeholder="search item"
          value={searchTerm}
          onChange={handleSearchChange}
          className="border-[#D9D9D9] border-[1px] my-4 rounded-[16px] w-full md:w-[500px] px-6 py-3 text-[#6D6662] text-[16px] font-[400] workSans appearance-none leading-tight focus:outline-none"
        />
        <div className="flex flex-wrap items-start gap-4">
          {categories.map((category) => (
            <button
              key={category}
              type="button"
              onClick={() => handleCategoryClick(category)}
              className={`hover:bg-[#5f1f5f] ${selectedCategory === category || (category === 'All' && selectedCategory === '') ? "bg-[#5f1f5f] text-white" : "bg-[#5900640D]"} w-fit py-3 px-[24px] rounded-[16px] text-[#616161] text-[16px] font-[500] workSans`}
            >
              {category}
            </button>
          ))}
        </div>
        <div>
          {loadingResources && <p className="text-[#616161] text-[16px] py-4 font-[500] workSans">Loading resources...</p>}
          {resourcesError && <p className="text-[#616161] text-[16px] font-[500] workSans">Error loading resources</p>}
          {filteredResources && filteredResources.length > 0 ? (
            filteredResources.map((resource, index) => (
              <div key={index} className="md:flex items-start my-4 gap-6 bg-[#5900641A] p-[40px] rounded-[16px]">
                <img src={resource.schoolimage} alt="course_image" className="w-[400px] h-[300px]" />
                <div>
                  <p className="font-[600] text-[32px] workSans text-[#011114]">{resource.schooltitle}</p>
                  <div className="flex items-center gap-4 py-[3%]">
                    <p className="text-[#590064] workSans text-[16px] font-[500]">{resource.schooldifficulty}</p>
                    <p className="text-[#590064] workSans text-[16px] font-[500]">|</p>
                    <p className="text-[#590064] workSans text-[16px] font-[500]">{resource.duration}</p>
                    <p className="text-[#590064] workSans text-[16px] font-[500]">|</p>
                    <p className="text-[#590064] workSans text-[16px] font-[500]">{resource.schoolcourses.length} topic{resource.schoolcourses.length > 1 ? 's' : ''}</p>
                  </div>
                  <p className="text-[#011114] text-[16px] font-[400] workSans">
                    {limitWords(resource.schooldesc, 24)}
                  </p>
                  <button
                    type="button"
                    onClick={() => navigate(`/resources/school/${resource.schooltitle.toLowerCase().replace(/\s+/g, '-')}`)}
                    className="linearGradient my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
                  >
                    Start Learning
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingResources ? "" : "No resources found"}</div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Resources;
