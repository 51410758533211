import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    inquiry: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Logic to handle form submission, such as sending an email
    const emailBody = `
      Full Name: ${formData.fullName}
      Email: ${formData.email}
      Inquiry: ${formData.inquiry}
    `;

    window.location.href = `mailto:otijoseph305@gmail.com?subject=Contact Form Submission&body=${encodeURIComponent(emailBody)}`;
  };

  return (
    <div className="p-[4%]">
      <div className="flex justify-center">
        <form onSubmit={handleSubmit} className="bg-[#590064] text-white py-[20] px-[20px] md:py-[40px] md:px-[80px] rounded-[16px] shadow-md w-full max-w-3xl">
          <h2 className="text-[36px] font-[700] workSans text-white mb-4 text-center">Contact Us</h2>
          <p className="mb-6 text-center text-[16px] font-[400] workSans text-[#fff]">Describe your project and leave us your contact info</p>
          <div className="md:flex gap-4 md:items-center">
            <div className="mb-4 md:flex-grow">
              <label className="block text-[14px] font-[700] workSans mb-1" htmlFor="fullName">Full name</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="w-full py-[10px] px-[16px] text-black rounded-[8px] border-[1px] border-[#D9D9D9] workSans font-[400] text-[16px]"
                required
              />
            </div>
            <div className="mb-4 md:flex-grow">
              <label className="block text-[14px] font-[700] workSans mb-1" htmlFor="email">Email address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full py-[10px] px-[16px] text-black rounded-[8px] border-[1px] border-[#D9D9D9] workSans font-[400] text-[16px]"
                required
              />
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-[14px] font-[700] workSans mb-1" htmlFor="inquiry">Request/Inquiry</label>
            <textarea
              id="inquiry"
              name="inquiry"
              value={formData.inquiry}
              onChange={handleChange}
              className="w-full py-[10px] px-[16px] text-black rounded-[8px] border-[1px] border-[#D9D9D9] workSans font-[400] text-[16px] h-32"
              required
            />
          </div>
          <button type="submit" className="linearGradient w-full rounded-[32px] mb-4 text-[14px] font-[500] workSans text-[#fff]">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
