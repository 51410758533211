import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from "../../images/logoblack.png";
import { Link } from 'react-router-dom';

const UserInfo = ({
  setPage, firstname, setFirstname,
  lastname, setLastname,
  username, setUsername,
  country, setCountry,
  state, setState,
}) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [loadingStates, setLoadingStates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stateError, setStateError] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://countriesnow.space/api/v0.1/countries/flag/unicode');
        setCountries(response.data.data);
      } catch (err) {
        setError('Failed to fetch countries');
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  const fetchStates = async () => {
    setLoadingStates(true);
    try {
      const response = await axios.post('https://countriesnow.space/api/v0.1/countries/states', {
        country: 'Nigeria'
      });
      setStates(response.data.data.states);
    } catch (err) {
      setStateError('Failed to fetch states');
    } finally {
      setLoadingStates(false);
    }
  };

  const handleCountryChange = (event) => {
    const country = event.target.value;
    setCountry(country);
    if (country === 'Nigeria') {
      fetchStates();
    } else {
      setStates([]);
    }
  };

  const handleStateChange = (event) => {
    const state = event.target.value;
    setState(state);
  }

  const areFieldsEmpty = () => {
    return !firstname || !lastname || !username || !country;
  };

  return (
    <div>
      <Link to="/"><img src={logo} alt="logo" /></Link>
      <p className="text-[#011114] text-[30px] font-[700] workSans my-3">Sign up</p>
      <p className="text-[#011114] text-[16px] pb-4 font-[400] workSans">
        Create an account with us and begin your learning journey
      </p>
      <div className="flex gap-4">
        <div className="mb-2">
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="firstName">
            First name
          </label>
          <input
            className="appearance-none border-[#D9D9D9] border-[1px] rounded-[8px] w-full py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none"
            id="firstName"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            type="text"
            placeholder="Karis"
          />
        </div>
        <div className="mb-2">
          <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="lastName">
            Last name
          </label>
          <input
            className="appearance-none border-[#D9D9D9] border-[1px] rounded-[8px] w-full py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none"
            id="lastName"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
            type="text"
            placeholder="Mmaju"
          />
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="username">
          Username
        </label>
        <input
          className="appearance-none border-[#D9D9D9] border-[1px] rounded-[8px] w-full py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          type="text"
          placeholder="karismmaju"
        />
      </div>
      <div className="mb-4">
        <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="country-select">
          Select a country:
        </label>
        {loading ? (
          <p>Loading countries...</p>
        ) : (
          <select
            id="country"
            className="appearance-none border-[#D9D9D9] border-[1px] rounded-[8px] w-full py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none"
            onChange={handleCountryChange}
          >
            {countries.map((country) => (
              <option key={country.name} value={country.name}>
                {country.name} {country.unicodeFlag}
              </option>
            ))}
          </select>
        )}
      </div>
      {country === 'Nigeria' && (
        <>
          <div className="mb-4">
            <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="state">
              State
            </label>
            {loadingStates ? (
              <p className="block text-[#616161] text-[14px] font-[600] workSans mb-2">Loading states...</p>
            ) : (
              <select
                id="state"
                onChange={handleStateChange}
                className="appearance-none border-[#D9D9D9] border-[1px] rounded-[8px] w-full py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none"
              >
                {states.map((state) => (
                  <option key={state.name} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            )}
          </div>
        </>
      )}
      <button
        type="button"
        onClick={() => setPage(1)}
        className={`linearGradient rounded-[32px] w-full text-[14px] font-[500] workSans text-[#fff] ${areFieldsEmpty() ? 'cursor-not-allowed' : ''}`}
        disabled={areFieldsEmpty()}
      >
        Next
      </button>
    </div>
  );
};

export default UserInfo;
