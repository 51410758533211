import React, { useRef, useEffect, useState } from 'react';
import * as faceapi from 'face-api.js';
import logo from "../../images/logoblack.png";
import { useNavigate, Link } from 'react-router-dom';
import { getUserIdFromLocalStorage } from './getUserId';
import "./style.css";

const VerifyIdentity = ({
  setPage, setViewModal,
  setViewErrorModal
}) => {
  const videoRef = useRef();
  const intervalRef = useRef(null);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDetecting, setIsDetecting] = useState(false);
  const navigate = useNavigate();
  const userId = getUserIdFromLocalStorage();

  const loadModels = async () => {
    const MODEL_URL = process.env.PUBLIC_URL + '/models';
    await Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
      faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
      faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
      faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      faceapi.nets.ageGenderNet.loadFromUri(MODEL_URL),
    ]);
    startVideo();
  };

  const startVideo = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      setIsLoading(false);
      setIsDetecting(true);
    } catch (error) {
      console.error('Error accessing webcam: ', error);
    }
  };

  const handleVideo = () => {
    setIsLoading(true);
    loadModels();
    setIsVideoVisible(true)
  }

  useEffect(() => {
    return () => {
      // Clear the interval when the component is unmounted
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };

  }, []);

  const handleVideoPlay = async () => {
    intervalRef.current = setInterval(async () => {
      if (videoRef.current) {
        const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks()
          .withFaceDescriptors()
          .withFaceExpressions()
          .withAgeAndGender();
        if (detections.length === 0) {
          console.log("empty!!")
        } else {
          const detection = detections[0];
          const { gender, genderProbability } = detection;

          if (gender === 'male' && genderProbability >= 0.85) {
            setViewErrorModal(true)
            setTimeout(() => {
              setPage(5);
              setIsDetecting(false);
              setViewErrorModal(false)
            }, 3000);
          } else if (gender === 'female' && genderProbability >= 0.85) {
            setViewModal(true);
            setTimeout(() => {
              // setPage(6);
              navigate(`/auth/payment/${userId}`)
              setViewModal(false);
              setIsDetecting(false);
            }, 2000);
          }
        }
      }
    }, 100);
  };

  return (
    <div>
      <div className="flex justify-between gap-2 items-center">
        <Link to="/"><img src={logo} alt="logo" /></Link>
        <button
          onClick={() => setPage(3)}
          type="button"
          className={`px-2 py-[4px] border-[#616161] text-[#011114] border-[1px] bg-[#fff] rounded-[50%]`}
        >
          &larr;
        </button>
      </div>
      <p className="text-[#011114] text-[30px] font-[700] workSans mt-3">Identity verification</p>
      <p className="text-[#011114] text-[16px] pb-4 font-[400] workSans">
        verify your identity
      </p>
      <div className="border-container">
        <div className="border-corner top-left"></div>
        <div className="border-corner top-right"></div>
        <div className="border-corner bottom-left"></div>
        <div className="border-corner bottom-right"></div>

        <div className={`external-border ${!isDetecting ? 'stop-animation' : ''}`}></div>

        {isVideoVisible && (
          <video
            ref={videoRef}
            autoPlay
            muted
            width="328"
            height="342"
            onPlay={handleVideoPlay}
            style={{ display: 'block', margin: 'auto' }}
          />
        )}
      </div>
      <div className="py-[6%]">
        <p className="text-[#011114] text-[16px] text-center font-[400] workSans">Scan your face to verify you are female</p>
        {isVideoVisible ? (
          <p className="text-[#011114] text-[16px] text-center pt-4 pb-4 font-[400] workSans">Make sure your face and ears are showing clearly</p>
        ) : (
          <div>
            <p className="text-[#011114] text-[16px] text-center pt-4 pb-4 font-[400] workSans">Ensure your background is very bright and visible!</p>
          </div>
        )}
      </div>
      <button
        type="button"
        onClick={handleVideo}
        className="linearGradient rounded-[32px] w-full text-[14px] font-[500] workSans text-[#fff]"
      >{isLoading ? "Loading..." : "Scan face"}</button>
    </div>
  );
};

export default VerifyIdentity;
