import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const SEND_OTP = 'SEND_OTP';
const api_url = process.env.REACT_APP_BASE_API_URL;
const SEND_OTP_URL = `${api_url}/api/auth/sendOtp`;

const initialState = {
  userOtp: [],
  loading: false,
  error: false,
}

export const sendOtp = createAsyncThunk(
  SEND_OTP,
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(SEND_OTP_URL, { email }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  },
);

const sendOtpReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SEND_OTP}/pending`:
      return {
        ...state,
        loading: true,
        error: false
      }
    case `${SEND_OTP}/fulfilled`:
      return {
        ...state,
        userOtp: action.payload,
        loading: false,
        error: false
      }
    case `${SEND_OTP}/rejected`:
      return {
        ...state,
        loading: false,
        error: true
      }
    default:
      return { userOtp: [], loading: false, error: false };
  }
}

export default sendOtpReducer;
