import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signup } from '../../redux/auth/signup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import womenInTech from "../eventImages/edited/women in tech.jpg"
import verifiedBadge from "../images/verified.png";
import verifiedFailed from "../images/error_verifying.png";
import UserInfo from "./signupProcess/UserInfo";
import Email from "./signupProcess/Email";
import VerifyEmail from "./signupProcess/VerifyEmail";
import Password from "./signupProcess/Password";
import VerifyIdentity from './signupProcess/VerifyIdentity';
import Help from './signupProcess/Help';
import Payment from './Payment';
import UploadID from './signupProcess/UploadID';
import UploadedID from './signupProcess/UploadedID';
import { getUserIdFromLocalStorage } from './signupProcess/getUserId';

const Signup = () => {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [viewModal, setViewModal] = useState(false);
  const [viewErrorModal, setViewErrorModal] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const role = "user";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const isVerified = false;
  const isBlocked = false;
  const isPaid = false;
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const userId = getUserIdFromLocalStorage();

  const createUser = () => {
    // e.preventDefault();
    const user = {
      firstname, lastname, username, role, email,
      password, isVerified, isBlocked, isPaid, country,
      state
    }
    dispatch(signup(user)).then((res) => {
      if (res.error) {
        toast.error(res?.payload, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setPage(4);
        if (res?.payload) {
          const { userId } = res?.payload;
          if (userId) localStorage.setItem('userId', userId);
        }
      }
    });
    setFirstname("");
    setLastname("");
    setUsername("");
    setEmail("");
    setPassword("");
    setCountry("");
    setState("");
  }

  const currentPage = () => {
    switch (page) {
      case 0:
        return <UserInfo
          firstname={firstname} setFirstname={setFirstname}
          lastname={lastname} setLastname={setLastname}
          username={username} setUsername={setUsername}
          country={country} setCountry={setCountry}
          state={state} setState={setState}
          setPage={setPage}
        />
      case 1:
        return <Email setPage={setPage} email={email} setEmail={setEmail} />
      case 2:
        return <VerifyEmail setPage={setPage} email={email} setEmail={setEmail} />
      case 3:
        return <Password setPage={setPage} password={password}
          setPassword={setPassword} createUser={createUser} />
      case 4:
        return <VerifyIdentity
          setPage={setPage}
          setViewModal={setViewModal}
          setViewErrorModal={setViewErrorModal}
        />
      case 5:
        return <Help setPage={setPage} />
      case 6:
        return <Payment setPage={setPage} />
      case 7:
        return <UploadID setPage={setPage} userId={userId} />
      case 8:
        return <UploadedID />
      default:
        return <UserInfo />
    }
  };

  return (
    <div className="homepageHero2 px-[5%] min-h-screen">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex justify-center">
        <div className="bg-[#FFFFFF] flex items-baseine gap-[5%] rounded-[24px] p-6 my-[5%]">
          <div>
            <img src={womenInTech} alt="auth_image" className="w-full hidden md:block md:w-[636px] md:h-[610px] rounded-3xl" />
          </div>
          <div>
            <form className="pt-3 pb-8">
              {currentPage()}
            </form>
            <p className="text-[#011114] text-[14px] font-[400] workSans text-center">
              Already have an account? <Link to="/auth/signin"><span className="heading">Log in</span></Link>
            </p>
          </div>
        </div>
      </div>
      {viewModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm md:w-full">
            <div className="flex flex-col items-center">
              <img src={verifiedBadge} alt="badge" className="mb-4" />
              <p className="text-[#616161] text-[16px] text-center pt-4 pb-4 font-[400] workSans">Your identity has been verified</p>
              <button
                type="button"
                className="linearGradient rounded-[32px] w-full text-[14px] mt-[25%] font-[500] workSans text-[#fff]"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
      {viewErrorModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm md:w-full">
            <div className="flex flex-col items-center">
              <img src={verifiedFailed} alt="badge" className="mb-4 w-[80px]" />
              <p className="text-[14px] workSans text-red-600">Verification failed</p>
              <p className="text-[#616161] text-[16px] text-center pt-4 pb-4 font-[400] workSans">
                Our system detected you to be male
              </p>
              <p className="text-[#616161] text-[16px] text-center pt-4 pb-4 font-[400] workSans">
                This platform is made for women!
              </p>
              <button
                type="button"
                className="linearGradient rounded-[32px] w-full text-[14px] mt-[25%] font-[500] workSans text-[#fff]"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
