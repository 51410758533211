import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { format } from 'date-fns';
import course from "./images/student-cap.png";
import liveSession from "./images/Live Broadcasting.png";
import resource from "./images/Information Resources.png";
import verified from "./images/Verified.png";
import Calendar from './Calendar';
import Counter from './Counter';
import courseimg from "./images/resources_image1.png";
import courseimg2 from "./images/resources_image2.png";
import courseimg3 from "./images/resources_image3.png";
import img1 from "../../components/eventImages/edited/women in tech.jpg";
import img2 from "../../components/eventImages/edited/IMG_12.jpg";
import img3 from "../../components/eventImages/edited/IMG_5.jpg";
import img4 from "../../components/eventImages/edited/IMG_1561.jpg";
import img9 from "../../components/eventImages/edited/IMG_1550.jpg"
import investorsImage from "./images/investorsImg.jpeg";
import videoimg from "./images/videoslide.png";
import Autoplay from "embla-carousel-autoplay";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../@shadcomponents/ui/carousel"
import "./index.css";
import "./accordion.css";

const Homepage = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const dateFormat = "MMMM";
  const plugin = React.useRef(
    Autoplay({ delay: 2000, stopOnInteraction: true })
  )

  const [activeIndex, setActiveIndex] = useState(0);
  const colors = ['#FFFFFF', '#FF9900', '#118539',];


  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const resources = [
    {
      img: course,
      title: "Beginner friendly"
    },
    {
      img: liveSession,
      title: "Live sessions"
    },
    {
      img: resource,
      title: "Up-to-date resources"
    },
    {
      img: verified,
      title: "Trusted and certified"
    },
  ];

  const events = [
    {
      sessionname: "Event name",
      sessiontime: "07:00pm (EST)",
      sessionmonth: "June",
      sessiondate: "2024-06-20T00:00:00.000+00:00",
      sessiondetails: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis",
    },
    {
      sessionname: "Name event",
      sessiontime: "01:00pm (EST)",
      sessionmonth: "July",
      sessiondate: "2024-06-27T00:00:00.000+00:00",
      sessiondetails: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis",
    },
    {
      sessionname: "Event",
      sessiontime: "12:00pm (EST)",
      sessionmonth: "July",
      sessiondate: "2024-06-20T00:00:00.000+00:00",
      sessiondetails: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis",
    },
    {
      sessionname: "Learn forex",
      sessiontime: "08:00pm (EST)",
      sessionmonth: "August",
      sessiondate: "2024-06-27T00:00:00.000+00:00",
      sessiondetails: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis",
    },
    {
      sessionname: "Learn pair",
      sessiontime: "06:30pm (EST)",
      sessionmonth: "September",
      sessiondate: "2024-06-27T00:00:00.000+00:00",
      sessiondetails: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis",
    },
  ];

  const docResources = [
    {
      coursetitle: "Candle stick",
      coursedifficulty: "Beginner",
      duration: "2hrs 20mins",
      numoftopics: 12,
      courseimg: courseimg,
      coursedescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate",
    },
    {
      coursetitle: "Currency Pair",
      coursedifficulty: "Beginner",
      duration: "2hrs 20mins",
      numoftopics: 12,
      courseimg: courseimg2,
      coursedescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate",
    },
    {
      coursetitle: "Stock",
      coursedifficulty: "Beginner",
      duration: "2hrs 20mins",
      numoftopics: 12,
      courseimg: courseimg3,
      coursedescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate",
    },
  ];

  const videoSlides = [
    {
      videotitle: "Candle stick",
      video: videoimg,
      videodescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate"
    },
    {
      videotitle: "Currency Pair",
      video: videoimg,
      videodescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate"
    },
    {
      videotitle: "Stock",
      video: videoimg,
      videodescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate"
    },
  ]

  const eventImages = [
    {
      id: 1,
      img: img1,
    },
    {
      id: 2,
      img: img2,
    },
    {
      id: 3,
      img: img3,
    },
    {
      id: 4,
      img: img4,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)'); // Small screen max width

    let interval;
    if (mediaQuery.matches) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % eventImages.length);
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [eventImages.length]);


  // const filteredEvents = events.filter(event =>
  //   isSameMonth(parseISO(event.sessionmonth), format(currentMonth, dateFormat))
  // );
  const filteredEvents = events.filter(event =>
    event.sessionmonth === format(currentMonth, dateFormat)
  );

  return (
    <div>
      <section className="homepageHero px-6">
        <div className="py-[4%]">
          <Navbar />
        </div>
        <h1 className="baskerville font-[600] md:font-[400] text-center text-[22px] md:text-[80px] mt-4 text-white">We are
          <span className="heading"> TheWaveTribe</span>
        </h1>
        <p className="workSans font-[400] text-[15px] md:text-[20px] text-[#fff] text-center my-2">
          Simplifying trading and financial education for
          <span className="heading"> women,</span> guiding them toward
        </p>
        <p className="workSans font-[400] text-[15px] md:text-[20px] text-[#fff] text-center my-2">
          <span className="heading"> financial sustainability </span>
          through financial literacy and personal development.
        </p>
        <p className="workSans font-[400] text-[15px] md:text-[20px] text-[#fff] text-center my-2">
          Join us with a one-time membership fee of <span className="heading">$10.</span>
        </p>
        <div className="flex justify-center py-[4%] gap-6">
          <Link to="/donate">
            <button type="button" className="rounded-[32px] border-[2px] border-[#C60AC7] px-[25px] py-[11px] text-[16px] font-[600] workSans signIn">
              Donate now
            </button>
          </Link>
          <Link to="/auth/signin">
            <button type="button" className="linearGradient rounded-[32px] text-[16px] font-[600] workSans text-[#fff]">
              Start learning
            </button>
          </Link>
        </div>
        <div className="flex-wrapper">
          {eventImages.map((img, index) => (
            <div
              key={img.id}
              className="image-container"
              style={{ display: currentIndex === index || !window.matchMedia('(max-width: 767px)').matches ? 'block' : 'none' }}
            >
              <img src={img.img} className="w-full md:h-[300px]" alt="events images" />
            </div>
          ))}
        </div>
      </section>
      <section className="bg-[#E20BE308] pb-8">
        <div className="flex justify-center px-[8%] mt-[-20px] md:mt-[-80px]">
          <div className="linearColor w-full rounded-[20px] py-4 px-[4%] flex justify-between items-center">
            {resources.map((item, index) => (
              <div key={index} className="py-4 px-4">
                <img className="w-[40px] lg:w-[70px] m-auto" src={item.img} alt={item.title} />
                <p className="text-[10px] md:text-[15px] text-center font-[400] workSans text-white">{item.title}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="px-[8%] bg-[#E20BE308]">
        <div className="py-[5%]">
          <p className="baskerville font-[600] md:font-[400] text-center text-[20px] md:text-[40px] mt-4 text-black">
            Register For Live Sessions With Our
            <span className="heading"> Calender</span>
          </p>
        </div>
        <div className="py-4 md:flex items-start md:justify-evenly gap-4">
          <div>
            <Calendar
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>
          <div className="md:max-w-[532px] h-[500px] overflow-auto yscrollbar">
            <p className="text-[#011114] font-[600] text-[30px] workSans">Events in {format(currentMonth, dateFormat)}</p>
            <div>
              {filteredEvents.length > 0 ? (
                filteredEvents.map((event, index) => (
                  <div key={index} className="bg-[#5900640D] rounded-lg p-4 my-4">
                    <p className="text-[#011114] font-[600] text-[20px] workSans py-2">{event.sessionname}</p>
                    <p className="text-[#011114] font-[600] text-[15px] workSans py-2">{event.sessiontime}</p>
                    <p className="text-[#616161] text-[13px] font-[400] workSans">{event.sessiondetails}</p>
                    <div className="flex justify-start">
                      <Link to="/auth/signin">
                        <button type="button" className="linearGradient my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]">
                          Register
                        </button>
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-[#616161] text-[13px] font-[400] workSans">
                  No events for this date.
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="homepageHero p-[8%]">
        <div className="pb-[5%]">
          <p className="baskerville font-[600] md:font-[400] text-center text-[20px] md:text-[40px] mt-4 text-white">
            Discover Our
            <span className="heading"> Goals</span>
          </p>
        </div>
        <div className="md:flex items-center gap-8">
          <div className="bg-[#ffffff] p-6 rounded-sm">
            <p className="text-[30px] font-[600] workSans text-black">Mission</p>
            <p className="workSans text-[16px] font-[400] text-[#011114]">
              Simplifying trading and financial education for women,
              guiding them toward financial sustainability through
              financial literacy and personal development.
            </p>
            {/* <p className="workSans text-[16px] font-[400] text-[#011114]">
              We strive to create an inclusive
              and supportive environment where women can learn, grow, and
              achieve financial independence through expert-led education and
              innovative trading solutions.
            </p> */}
          </div>
          <div className="bg-[#ffffff] p-6 rounded-sm mt-2">
            <p className="text-[30px] font-[600] workSans text-black">Vision</p>
            <p className="workSans text-[16px] font-[400] text-[#011114]">
              <b>Empowering women in forex:</b> Building a Supportive Community
              for Financial Independence, literacy, and Growth.
            </p>
            {/* <p className="workSans text-[16px] font-[400] text-[#011114]">
              We strive to create an inclusive
              and supportive environment where women can learn, grow, and
              achieve financial independence through expert-led education and
              innovative trading solutions.
            </p> */}
          </div>
        </div>
      </section>
      <section className="px-[8%] py-[4%] bg-[#E20BE308]">
        <div className="md:flex justify-between items-center gap-6">
          <div>
            <p className="text-[30px] font-[600] workSans text-black">Our Reach So Far</p>
          </div>
          <div className="flex md:flex-none gap-2 md:gap-4">
            <div>
              <Counter targetNumber={6000} />
              <p className="workSans text-[16px] py-2 font-[400] text-[#000]">Community members</p>
            </div>
            <div>
              <Counter targetNumber={61} />
              <p className="workSans text-[16px] py-2 font-[400] text-[#000]">Countries covered</p>
            </div>
            <div>
              <Counter targetNumber={1} />
              <p className="workSans text-[16px] py-2 font-[400] text-[#000]">Physical event hosted</p>
            </div>
          </div>
        </div>
      </section>
      <section className="p-[8%] bg-[#ffffff]">
        <div className="md:flex justify-center items-start gap-8">
          <div>
            <img className="w-[400px] h-[350px] rounded-sm" src={img9} alt="collaborate" />
          </div>
          <div className="md:w-[545px]">
            <p className="text-[30px] font-[600] workSans text-black">Encouraging partnerships and collaborations</p>
            <p className="workSans text-[16px] my-4 font-[400] text-[#000]">
              We are excited to welcome partners and corporations who share our vision and are willing to make a positive impact lives.
            </p>
            <a href="mailto:otijoseph305@gmail.com?subject=Partnership Request with PnPFX" target="_blank">
              <button type="button" className="linearGradient rounded-[32px] text-[16px] font-[600] workSans text-[#fff]">
                Partner with us
              </button>
            </a>
          </div>
        </div>
      </section>
      <section className="px-[8%] py-[3%] bg-[#E20BE308]">
        <div>
          <div className="pb-[5%]">
            <p className="baskerville font-[600] md:font-[400] text-center text-[20px] md:text-[40px] mt-4 text-black">
              Check out our
              <span className="heading"> Resources</span>
            </p>
          </div>
          <div className="accordion w-full">
            {docResources.map((resource, index) => (
              <div key={index} className="accordion-item my-4">
                <div className={`accordion-header rounded-3xl ${activeIndex === index ? "active" : ""}`} onClick={() => toggleAccordion(index)}>
                  <div className="header-content">
                    <h2 className="text-[30px] font-[600] workSans text-white">{resource.coursetitle}</h2>
                  </div>
                  <div className="header-icon">
                    {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                </div>
                <div className={`accordion-content rounded-3xl p-6 ${activeIndex === index ? "show" : ""}`}>
                  <img src={resource.courseimg} className="w-full h-fit rounded-lg" alt="Course" />
                  <div>
                    <div className="flex justify-between gap-4 items-center">
                      <h2 className="text-[30px] font-[600] workSans text-white">{resource.coursetitle}</h2>
                      <div className="header-icon cursor-pointer text-white" onClick={() => toggleAccordion(index)}>
                        {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                      </div>
                    </div>
                    <div className="flex gap-[10px] text-[14px] workSans py-4 text-white font-[400]">
                      <span>{resource.coursedifficulty}</span>
                      <span>|</span>
                      <span>{resource.duration}</span>
                      <span>|</span>
                      <span>{resource.numoftopics} topics</span>
                    </div>
                    <p className="text-[14px] workSans pb-4 text-white font-[400]">{resource.coursedescription}</p>
                    <Link to="/auth/signin">
                      <button type="button" className="linearGradient mb-4 mt-6 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]">
                        Start learning
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="p-[8%] bg-[#ffffff]">
        <div className="md:flex justify-center items-start gap-8">
          <div className="md:w-[545px]">
            <p className="text-[30px] font-[600] workSans text-black">Supporting the Women in PipsnPearl effort.</p>
            <p className="workSans text-[16px] my-4 font-[400] text-[#000]">
              Help us empower women in forex trading by donating to PipsnPearl.
              Your contribution will enhance our educational resources,
              improve platform technology, and support community initiatives.
              Together, we can foster financial independence and confidence
              for women. Donate today and make a difference.
              Thank you for your support.
            </p>
            <Link to="/donate">
              <button type="button" className="linearGradient rounded-[32px] my-4 text-[16px] font-[600] workSans text-[#fff]">
                Donate Now
              </button>
            </Link>
          </div>
          <div>
            <img className="w-[500px] h-[350px] rounded-sm" src={img1} alt="collaborate" />
          </div>
        </div>
      </section>
      <section className="px-[8%] bg-[#FFFFFF] my-[8%]">
        <div className="bg-[#FF9900] rounded-[16px] py-[60px] px-[30px] lg:px-[70px] md:flex items-start justify-center gap-8">
          <div className="md:w-[545px]">
            <p className="baskerville font-600 lg:font-[400] text-center lg:text-start text-[30px] lg:text-[70px] text-black">Become an <span className="text-[#ffffff]">Investor!</span></p>
            <p className="workSans text-[16px] font-[400] text-[#011114]">
              Are you interested in earning a steady return on your investment without having to navigate the complexities of forex trading? Let our experienced team trade on your behalf. Our exclusive forex trading services are designed to deliver consistent returns while ensuring your capital is in safe hands.
            </p>
            <div className="flex justify-center lg:justify-start">
              <button type="button" className="bg-[#fff] rounded-[32px] mt-6 px-[70px] py-[15px] text-[#011114] text-[14px] font-[600] workSans">
                Register
              </button>
            </div>
          </div>
          <div>
            <img src={investorsImage} className="hidden lg:block w-[400px] h-[380px]" alt="investors_image" />
          </div>
        </div>
      </section>
      <section className="px-[8%] bg-[#011114]">
        <div className="py-[5%]">
          <p className="baskerville font-[600] md:font-[400] text-center text-[20px] md:text-[40px] mt-4 text-white">
            Learn virtually with our self explanatory
            <span className="heading"> Videos</span>
          </p>
        </div>
        <Carousel
          plugins={[plugin.current]}
          className="w-full pb-[7%] my-class m-auto"
          onMouseEnter={plugin.current.stop}
          onMouseLeave={plugin.current.reset}
        >
          <CarouselContent className="">
            {videoSlides.map((content, index) => (
              <CarouselItem key={index} className="">
                <div
                  className="mt-4 rounded-2xl p-6 w-full lg:flex lg:items-start gap-[1.5rem]"
                  style={{ backgroundColor: colors[index % colors.length] }}
                >
                  <img src={content.video} className="w-full" alt={content.videotitle} />
                  <div>
                    <h2 className={`text-[30px] font-[600] workSans ${index === 2 ? "text-white" : "text-black"}`}>{content.videotitle}</h2>
                    <p className={`text-[14px] workSans pb-4 ${index === 2 ? "text-white" : "text-black"} font-[400]`}>{content.videodescription}</p>
                    <Link to="/auth/signin">
                      <button type="button" className="bg-black mb-4 px-4 py-2 mt-6 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]">
                        Watch now
                      </button>
                    </Link>
                  </div>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious className="linearColor border-none" />
          <CarouselNext className="linearColor border-none" />
        </Carousel>
        <Footer />
        <hr className="pb-2" />
        <p className="text-center text-white text-[12px] pb-[6%] mt-4 workSans font-[400]">Copyright&copy;2024 PipsnPearlfx</p>
      </section>
    </div>
  );
};

export default Homepage;
