import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchEvents } from '../../redux/events/events';
import { fetchUser } from '../../redux/auth/getUser';
import PerformanceChart from './PerformanceChart';
import Calendar from '../homepage/Calendar';
import { format } from 'date-fns';
import "./style.css";

const UserDashboard = () => {
  const { events, loadingEvents, eventsError } = useSelector((state) => state.getEvents);
  const { user, loadingUser, userError } = useSelector((state) => state.getUser);
  const userId = localStorage.getItem('userId');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialData = [30, 50, 40, 70, 50, 80, 70, 90, 60, 80, 70, 50];
  const [chartData, setChartData] = useState(initialData);

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const dateFormat = "MMMM";

  const handleCardClick = (newData) => {
    setChartData(newData);
  };

  useEffect(() => {
    dispatch(fetchEvents());
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);

  const filteredEvents = events.filter(event =>
    event.sessionmonth === format(currentMonth, dateFormat)
  );

  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  return (
    <div className="px-[4%]">
      <h1 className="text-[#121212] text-[28px] py-6 font-[500] workSans">Hey {user?.username || "User"} - welcome back!</h1>
      <div className="flex flex-wrap items-center gap-6">
        <div
          className="shadow bg-[#5900640D] cursor-pointer p-[16px] rounded-[8px] w-full md:w-[290px]"
          onClick={() => handleCardClick([40, 60, 50, 80, 60, 90, 80, 100, 70, 90, 80, 60])}
        >
          <p className="text-[#7C067D] font-[700] text-[28px] workSans">{user?.registeredEvents?.length || 0}</p>
          <p className="text-[#121212] py-4 text-[16px] font-[400] workSans">Events registered for</p>
          <div className="flex items-center gap-2 text-[12px] font-[400] workSans">
            <span className="text-[#F7C14C]">{user?.registeredEvents?.length / 20}%</span>
            <span className="text-[#808080]">compared to last month</span>
          </div>
        </div>
        <div
          className="shadow bg-[#5900640D] cursor-pointer p-[16px] rounded-[8px] w-full md:w-[290px]"
          onClick={() => handleCardClick([50, 70, 60, 90, 70, 110, 80, 100, 90, 70])}
        >
          <p className="text-[#7C067D] font-[700] text-[28px] workSans">{user?.completedModuleContents || 0}</p>
          <p className="text-[#121212] py-4 text-[16px] font-[400] workSans">Resources completed</p>
          <div className="flex items-center gap-2 text-[12px] font-[400] workSans">
            <span className="text-[#F7C14C]">{user?.completedModuleContents / 20}%</span>
            <span className="text-[#808080]">compared to last month</span>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <PerformanceChart data={chartData} />
      </div>
      <div className="py-[5%]">
        <p className="baskerville font-[400] text-center text-[40px] mt-4 text-black">
          Register For Live Sessions With Our
          <span className="heading"> Calender</span>
        </p>
      </div>
      <div className="flex justify-center items-center mb-[4%]">
        <Calendar
          currentMonth={currentMonth}
          setCurrentMonth={setCurrentMonth}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </div>
      <p className="text-[#011114] font-[600] my-6 text-center text-[30px] workSans">Events in {format(currentMonth, dateFormat)}</p>
      {loadingEvents && <p className="text-[#616161] text-[16px] py-4 font-[500] workSans">Loading events...</p>}
      {eventsError && <p className="text-[#616161] text-[16px] py-4 font-[500] workSans">Error loading events: {eventsError}</p>}
      <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 ${filteredEvents?.length > 1 ? "h-[568px] overflow-auto yscrollbar" : ""} gap-6`}>
        {filteredEvents && filteredEvents.length > 0 ? (
          filteredEvents.map((event) => (
            <div key={event._id} className="bg-[#5900640D] rounded-lg p-4 my-4">
              <p className="text-[#011114] font-[600] text-[20px] workSans py-2">{event.sessionname}</p>
              <p className="text-[#011114] font-[600] text-[15px] workSans py-2">{event.sessiontime}</p>
              <p className="text-[#616161] text-[13px] font-[400] workSans">{limitWords(event.sessiondetails, 18)}</p>
              <div className="flex justify-start">
                <button
                  type="button"
                  onClick={() => navigate(`/events/${event._id}`)}
                  className="linearGradient my-4 rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
                >
                  Register
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-[#616161] text-[16px] font-[500] py-4 workSans">{loadingEvents ? "" : "No events found for this month"}</div>
        )}
      </div>
    </div>
  );
};

export default UserDashboard;
