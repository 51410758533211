import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchEvents } from '../../redux/events/events';
import downarr from "./images/arrow_forward_ios.png";

const Events = () => {
  const { events, loadingEvents, eventsError } = useSelector((state) => state.getEvents);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [SelectedLocationCategory, setSelectedLocationCategory] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [locationDropdown, setLocationDropdown] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);

  const categories = ['Forex', 'Crypto', 'Tech', 'Personal development', 'Business', 'Finance', 'Faith', 'All'];
  useEffect(() => {
    const date = new Date();
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December', 'All'
    ];
    const currentMonth = `${monthNames[date.getMonth()]}`;
    setSelectedMonth(currentMonth);
  }, []);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
    'October', 'November', 'December', 'All'];
  const locations = ['Physical event', 'Webinar'];

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category === 'All' ? '' : category);
  };

  const handleMonthClick = (month) => {
    setSelectedMonth(month === 'All' ? '' : month);
    setIsDropdownOpen(false);
    setButtonActive(false);
  };

  const handleLocationClick = (location) => {
    setSelectedLocationCategory(location)
    setLocationDropdown(false);
    setButtonActive(false);
  }

  const handleLocationDropdown = () => {
    setLocationDropdown(!locationDropdown)
    setButtonActive(true);
  }

  const handleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
    setButtonActive(true);
  }

  const handleButtonClick = () => {
    setIsDropdownOpen(false);
    setLocationDropdown(false);
    setButtonActive(false);
  }

  const filteredEvents = events
    .filter((event) =>
      event.sessionname.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((event) =>
      selectedCategory ? event.category.toLowerCase() === selectedCategory.toLowerCase() : true
    )
    .filter((event) =>
      selectedMonth ? event.sessionmonth === selectedMonth : true
    ).filter((event) =>
      SelectedLocationCategory ? event.locationcategory.toLowerCase() === SelectedLocationCategory.toLowerCase() : true
    );

  useEffect(() => {
    dispatch(fetchEvents())
  }, [dispatch]);

  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };


  return (
    <div>
      {buttonActive && (
        <button
          onClick={handleButtonClick}
          className="w-screen h-screen cursor-auto fixed"></button>
      )}
      <section className="px-[4%]">
        <div className="">
          <div className="baskerville font-[600] flex justify-center gap-4 items-center text-[15px] md:text-[18px] lg:text-[25px] mt-4 text-black">
            <span className="heading">{SelectedLocationCategory || 'Physical & Online'}</span>
            <img
              src={downarr}
              alt="down_arrow"
              onClick={handleLocationDropdown}
              className="cursor-pointer w-[25px] ml-[-10px]"
            />
            {locationDropdown && (
              <div className="fixed h-[120px] mt-[15%] md:w-[373px] overflow-auto bg-white shadow-md rounded-md">
                {locations.map((location) => (
                  <div
                    key={location}
                    onClick={() => handleLocationClick(location)}
                    className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                  >
                    <p className="text-[#333333] font-[400] workSans text-[15px] md:text-[24px]">{location}</p>
                  </div>
                ))}
              </div>
            )}
            Events in
            <span className="heading">{selectedMonth || 'All months'}</span>
            <img
              src={downarr}
              alt="down_arrow"
              onClick={handleDropdown}
              className="cursor-pointer w-[25px] ml-[-10px]"
            />
            {isDropdownOpen && (
              <div className="fixed h-[388px] mt-[50%] md:mt-[25%] md:w-[373px] overflow-auto bg-white shadow-md rounded-md">
                {months.map((month) => (
                  <div
                    key={month}
                    onClick={() => handleMonthClick(month)}
                    className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                  >
                    <p className="text-[#333333] font-[400] workSans text-[15px] md:text-[24px]">{month}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <input
          type="search"
          placeholder="search item"
          value={searchTerm}
          onChange={handleSearchChange}
          className="border-[#D9D9D9] border-[1px] rounded-[16px] w-full md:w-[500px] px-6 py-3 text-[#6D6662] text-[16px] font-[400] workSans appearance-none leading-tight focus:outline-none"
        />
        <div className="flex flex-wrap justify-between items-start gap-4 my-4">
          {categories.map((category) => (
            <button
              key={category}
              type="button"
              onClick={() => handleCategoryClick(category)}
              className={`hover:bg-[#5f1f5f] ${selectedCategory === category || (category === 'All' && selectedCategory === '') ? "bg-[#5f1f5f] text-white" : "bg-[#5900640D]"} w-fit py-3 px-[24px] rounded-[16px] text-[#616161] text-[16px] font-[500] workSans`}
            >
              {category}
            </button>
          ))}
        </div>
        {loadingEvents && <p className="text-[#616161] text-[16px] py-4 font-[500] workSans">Loading events...</p>}
        {eventsError && <p className="text-[#616161] text-[16px] py-4 font-[500] workSans">Error loading events: {eventsError}</p>}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
          {filteredEvents && filteredEvents.length > 0 ? (
            filteredEvents.map((event) => (
              <div key={event._id} className="bg-[#E20BE308] h-full rounded-[32px] p-[24px]">
                <img src={event.eventimage} alt="event_image" className="w-full h-[250px] rounded-[8px]" />
                <div className="py-[3%]">
                  <p className="font-[600] text-[32px] workSans text-[#011114]">{event.sessionname}</p>
                  <p className="font-[400] text-[16px] workSans text-[#011114]">{limitWords(event.sessiondetails, 18)}</p>
                  <p className="text-[#011114] font-[700] text-[14px] workSans">{event.sessiontime}</p>
                </div>
                <button
                  type="button"
                  onClick={() => navigate(`/events/${event._id}`)}
                  className="linearGradient rounded-[32px] text-[14px] font-[500] workSans text-[#fff]"
                >
                  Open event
                </button>
              </div>
            ))
          ) : (
            <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingEvents ? "" : "No events found for this month"}</div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Events;
