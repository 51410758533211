import React from 'react';
import logo from "../../images/logoblack.png";
import { useNavigate, Link } from 'react-router-dom';

const UploadedID = () => {
  const navigate = useNavigate()
  const handleBack = () => {
    window.location.reload();
    navigate("/")
  }

  return (
    <div>
      <Link to="/"><img src={logo} alt="logo" /></Link>
      <p className="text-[#011114] text-[30px] font-[700] workSans mt-3">Identity verification</p>
      <p className="mb-4 text-[#011114] text-[16px] pb-4 font-[400] workSans">
        Your ID is being processed
      </p>
      <p className="mb-4 text-[#011114] text-[16px] pb-4 font-[400] workSans">
        Thank you for submitting your document for verification. If you are verified as female, you will receive an email with instructions to proceed with payment.
      </p>
      <p className="mb-4 text-[#011114] text-[16px] pb-4 font-[400] workSans">
        If you do not receive a response within 48 hours, please contact our support team for assistance.
      </p>
      <button
        type="button"
        onClick={handleBack}
        className="linearGradient rounded-[32px] w-full text-[14px] font-[500] workSans text-[#fff]"
      >
        Home
      </button>
    </div>
  );
};

export default UploadedID;
