import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchVideos } from '../../redux/videos/getVideos';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import axios from 'axios';
import "./style.css";

const Webinar = () => {
  const { videos, loadingVideos, videosError } = useSelector((state) => state.getVideos);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewTimeoutRef = useRef(null);
  const categories = ['Forex', 'Crypto', 'Tech', 'Personal development', 'Finance', 'Business', 'Faith', 'All'];
  const difficulties = ['Beginner', 'Intermediate', 'Advanced'];
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedDifficulty, setSelectedDifficulty] = useState("");
  const api_url = process.env.REACT_APP_BASE_API_URL;

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category === 'All' ? '' : category);
  };

  const handleDifficultyClick = (difficulty) => {
    setSelectedDifficulty(difficulty)
  }

  const filteredVideos = videos
    .filter((video) =>
      video.videotitle.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((video) =>
      selectedCategory ? video.category.toLowerCase() === selectedCategory.toLowerCase() : true
    ).filter((video) =>
      selectedDifficulty ? video.difficulty.toLowerCase() === selectedDifficulty.toLowerCase() : true
    )

  useEffect(() => {
    dispatch(fetchVideos());
  }, [dispatch])

  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "do MMMM, yyyy");
  };

  const incrementViews = async (videoId) => {
    try {
      await axios.post(`${api_url}/api/livevideo/${videoId}/`);
      console.log("sent")
    } catch (error) {
      console.error('Failed to increment views', error);
    }
  };

  const handlePlay = (videoId) => {
    if (viewTimeoutRef.current) {
      clearTimeout(viewTimeoutRef.current);
    }

    const viewedVideos = JSON.parse(localStorage.getItem('viewedVideos') || '[]');
    if (!viewedVideos.includes(videoId)) {
      viewTimeoutRef.current = setTimeout(() => {
        incrementViews(videoId);
        viewedVideos.push(videoId);
        localStorage.setItem('viewedVideos', JSON.stringify(viewedVideos));
      }, 10000);
    }
  };

  return (
    <div className="p-[4%]">
      <h1 className="text-[#121212] text-[28px] font-[700] workSans">Video Session</h1>
      <p className="text-[#121212] text-[18px] pb-6 font-[400] workSans">
        Learn virtually with our self explanatory Videos, You can start by clicking on any of the difficulty levels below
      </p>
      {['Forex', 'Crypto', 'Tech', 'All'].includes(selectedCategory) || selectedCategory === '' ? (
        <div className="flex flex-wrap items-center gap-6 pb-6">
          {difficulties.map((difficulty) => (
            <div
              key={difficulty}
              onClick={() => handleDifficultyClick(difficulty.toLowerCase())}
              className={`hover:bg-[#58006434] ${selectedDifficulty === difficulty.toLowerCase() ? 'difficulty_bg' : 'bg-[#5900640D]'} cursor-pointer lg:w-[300px] rounded-[8px] py-[10px] px-[10px] md:py-[30px] md:px-[40px]`}
            >
              <p className={`workSans font-[600] text-[15px] md:text-[20px] text-center ${selectedDifficulty === difficulty.toLowerCase() ? 'text-[#FFFFFF]' : 'text-[#011114]'}`}>
                {difficulty}
              </p>
            </div>
          ))}
        </div>
      ) : null}
      <input
        type="search"
        placeholder="search item"
        value={searchTerm}
        onChange={handleSearchChange}
        className="border-[#D9D9D9] border-[1px] rounded-[16px] w-full md:w-[500px] px-6 py-3 text-[#6D6662] text-[16px] font-[400] workSans appearance-none leading-tight focus:outline-none"
      />
      <div className="flex flex-wrap justify-between items-start gap-4">
        {categories.map((category) => (
          <button
            key={category}
            type="button"
            onClick={() => handleCategoryClick(category)}
            className={`hover:bg-[#5f1f5f] ${selectedCategory === category || (category === 'All' && selectedCategory === '') ? "bg-[#5f1f5f] text-white" : "bg-[#5900640D]"} w-fit py-3 px-[24px] rounded-[16px] text-[#616161] text-[16px] font-[500] workSans`}
          >
            {category}
          </button>
        ))}
      </div>
      {loadingVideos && <p className="text-[#616161] text-[16px] py-4 font-[500] workSans">Loading videos...</p>}
      {videosError && <p className="text-[#616161] text-[16px] py-4 font-[500] workSans">Error loading videos: {videosError}</p>}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 my-4">
        {filteredVideos && filteredVideos.length > 0 ? (
          filteredVideos.map((video) => (
            <div className="bg-[#5900641A] rounded-[24px] p-[24px]" key={video._id}>
              <video
                className="w-full h-[250px] z-50 custom-video"
                controls
                onPlay={() => handlePlay(video._id)}
                onPause={() => clearTimeout(viewTimeoutRef.current)}
                onEnded={() => clearTimeout(viewTimeoutRef.current)}
              >
                <source src={video.videourl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="cursor-pointer" onClick={() => navigate(`/video_course/${video._id}`)}>
                <p className="font-[600] text-[32px] workSans pt-5 text-[#011114]">{video.videotitle}</p>
                <p className="text-[#616161] text-[14px] font-[400] workSans">Uploaded {formatDate(video.createdAt)}</p>
                <div className="flex flex-wrap items-center gap-4 py-5">
                  <p className="text-[rgb(97,97,97)] workSans text-[16px] font-[500]">{video.difficulty}</p>
                  <p className="text-[#616161] workSans text-[16px] font-[500]">|</p>
                  <p className="text-[#616161] workSans text-[16px] font-[500]">{video.duration}</p>
                  <p className="text-[#616161] workSans text-[16px] font-[500]">|</p>
                  <p className="text-[#616161] workSans text-[16px] font-[500]">{video.views} view{video.views.length !== 0 ? 's' : ''}</p>
                  <p className="text-[#616161] workSans text-[12px] bg-[#E20BE333] rounded-[8px] px-[8px] py-[2px] font-[500]">{video.category}</p>
                </div>
                <div className="">
                  <p className="text-[#011114] font-[400] workSans text-[16px]">
                    {limitWords(video.videodescription, 24)}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingVideos ? "" : "No videos found"}</div>
        )}
      </div>
    </div>
  );
};

export default Webinar;
