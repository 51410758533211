import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithSession } from '../session';

const GET_USER = "GET_USER";
const api_url = process.env.REACT_APP_BASE_API_URL;
const GET_USER_URL = `${api_url}/api/auth/getuser`;

const initialState = {
  user: [],
  loadingUser: false,
  userError: false,
}

export const fetchUser = createAsyncThunk(
  GET_USER,
  async (id, { rejectWithValue }) => {
    try {
      const response = await fetchWithSession(`${GET_USER_URL}/${id}`, {
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const getUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_USER}/pending`:
      return {
        ...state,
        loadingUser: true,
        userError: false
      }
    case `${GET_USER}/fulfilled`:
      return {
        user: action.payload,
        loadingUser: false,
        userError: false
      }
    case `${GET_USER}/rejected`:
      return {
        ...state,
        loadingUser: false,
        userError: true
      }
    default:
      return { ...state, loadingUser: false, userError: false };
  }
}

export default getUserReducer;
