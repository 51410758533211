import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithSession } from '../session';

const GET_RESOURCES = "GET_RESOURCES";
const api_url = process.env.REACT_APP_BASE_API_URL;
const GET_RESOURCES_URL = `${api_url}/api/resources`;

const initialState = {
  resources: [],
  loadingResources: false,
  resourcesError: false,
}

export const fetchResources = createAsyncThunk(
  GET_RESOURCES,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWithSession(`${GET_RESOURCES_URL}`, {
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const getResourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_RESOURCES}/pending`:
      return {
        ...state,
        loadingResources: true,
        resourcesError: false
      }
    case `${GET_RESOURCES}/fulfilled`:
      return {
        resources: action.payload,
        loadingResources: false,
        resourcesError: false
      }
    case `${GET_RESOURCES}/rejected`:
      return {
        ...state,
        loadingResources: false,
        resourcesError: true
      }
    default:
      return { ...state, loadingResources: false, resourcesError: false };
  }
}

export default getResourcesReducer;
