import React, { useState, useEffect } from 'react';
import StripePaymentForm from './StripePaymentForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const Stripe = ({ setViewPaymentModal, userId, setPage }) => {
  const stripePromise = loadStripe('pk_test_51Pcv2YI0OYBmfZOCeIWNRTNMBUmoSz57rW0QEoakPBr6JITYHyTqHTdh1z7Lv7sVTGICXlWDGmSsoaN62jBeOPHE00A5JLxTGA');
  const [stripeError, setStripeError] = useState(false);

  useEffect(() => {
    stripePromise.catch((error) => {
      setStripeError(true);
    });
  }, []);

  return (
    <div>
      {stripeError ? (
        <div>Failed to load payment system. Please try again later.</div>
      ) : (
        <Elements stripe={stripePromise}>
          <StripePaymentForm setViewPaymentModal={setViewPaymentModal} userId={userId} setPage={setPage} />
        </Elements>
      )}
    </div>
  );
};

export default Stripe;
