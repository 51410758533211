import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithSession } from '../session';

const FETCH_CART = "FETCH_CART";
const api_url = process.env.REACT_APP_BASE_API_URL;
const FETCH_CART_URL = `${api_url}/api/cart`;

const initialState = {
  cart: [],
  loadingCart: false,
  cartError: false,
}

export const fetchCart = createAsyncThunk(
  FETCH_CART,
  async (userId, { rejectWithValue }) => {
    try {
      const response = await fetchWithSession(`${FETCH_CART_URL}/${userId}`, {
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.payload.data);
    }
  }
);

const getCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_CART}/pending`:
      return {
        ...state,
        loadingCart: true,
        cartError: false
      }
    case `${FETCH_CART}/fulfilled`:
      return {
        cart: action.payload,
        loadingCart: false,
        cartError: false
      }
    case `${FETCH_CART}/rejected`:
      return {
        ...state,
        loadingCart: false,
        cartError: true
      }
    default:
      return { ...state, loadingCart: false, cartError: false };
  }
}

export default getCartReducer;
