import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithSession } from '../session';

const FETCH_MERCH = "FETCH_MERCH";
const api_url = process.env.REACT_APP_BASE_API_URL;
const FETCH_MERCH_URL = `${api_url}/api/merch/products`;

const initialState = {
  merch: [],
  loadingMerch: false,
  merchError: false,
}

export const fetchMerch = createAsyncThunk(
  FETCH_MERCH,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWithSession(`${FETCH_MERCH_URL}`, {
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const getMerchReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_MERCH}/pending`:
      return {
        ...state,
        loadingMerch: true,
        merchError: false
      }
    case `${FETCH_MERCH}/fulfilled`:
      return {
        merch: action.payload,
        loadingMerch: false,
        merchError: false
      }
    case `${FETCH_MERCH}/rejected`:
      return {
        ...state,
        loadingMerch: false,
        merchError: true
      }
    default:
      return { ...state, loadingMerch: false, merchError: false };
  }
}

export default getMerchReducer;
