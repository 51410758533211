import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithSession } from '../session';

const CREATE_VIDEO = "CREATE_VIDEO";
const api_url = process.env.REACT_APP_BASE_API_URL;
const CREATE_VIDEO_URL = `${api_url}/api/livevideo/create`;

const initialState = {
  video: [],
  loadingVideo: false,
  videoError: false,
}

export const postVideo = createAsyncThunk(
  CREATE_VIDEO,
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetchWithSession(`${CREATE_VIDEO_URL}`, {
        method: 'POST',
        body: data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const createVideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_VIDEO}/pending`:
      return {
        ...state,
        loadingVideo: true,
        videoError: false
      }
    case `${CREATE_VIDEO}/fulfilled`:
      return {
        ...state,
        video: action.payload,
        loadingVideo: false,
        videoError: false
      }
    case `${CREATE_VIDEO}/rejected`:
      return {
        ...state,
        loadingVideo: false,
        videoError: true
      }
    default:
      return { video: [], loadingVideo: false, videoError: false };
  }
}

export default createVideoReducer;
