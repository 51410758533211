import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCart } from '../../redux/merch/fetchCart';
import { fetchWithSession } from '../../redux/session';
import { useNavigate } from 'react-router-dom';
import { regions } from './regions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const InputField = ({ label, type = "text", value, onChange, selectOptions = [] }) => {
  return (
    <div className="relative border border-gray-300 rounded">
      <label className="absolute workSans -top-3 left-2 bg-white px-1 text-gray-700 text-sm">{label}</label>
      {type === "select" ? (
        <select className="mt-2 block workSans w-full border-none outline-none" value={value} onChange={onChange}>
          {selectOptions.map((option, index) => (
            <option key={index}>{option}</option>
          ))}
        </select>
      ) : (
        <input
          type={type}
          value={value}
          className="mt-2 block w-full workSans border-none outline-none"
          onChange={onChange}
        />
      )}
    </div>
  );
};

const Checkout = () => {
  const publicKey = "pk_test_043a090c91a3279a4eaff9f6efc3fcd274ada0a7";
  const { cart, loadingCart } = useSelector((state) => state.getCart);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const api_url = process.env.REACT_APP_BASE_API_URL;

  const userId = localStorage.getItem('userId');
  const totalQuantityProduct = cart?.cart?.items?.reduce((product, item) => product + item.quantity, 0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [email, setEmail] = useState();
  const [region, setRegion] = useState(regions[0]);
  const [city, setCity] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('Paystack');
  const [orderId, setOrderId] = useState("");
  const [orderTotal, setOrderTotal] = useState(0);
  const [isOrderCreated, setIsOrderCreated] = useState(false);
  console.log("orderTotal", orderTotal)
  const amount = orderTotal;

  useEffect(() => {
    if (userId) {
      dispatch(fetchCart(userId));
    }
  }, [dispatch, userId]);

  const handleCheckout = async (productId) => {
    setLoading(true);
    const url = `${api_url}/api/checkout`;

    const options = {
      method: 'POST',
      body: {
        userId,
        productId,
        firstName,
        lastName,
        phoneNumber,
        deliveryAddress,
        region,
        city,
        paymentMethod,
      },
    };

    try {
      const response = await fetchWithSession(url, options);

      if (response.status === 200) {
        alert('Order created successfully. Proceed to payment.');
        setOrderId(response?.data?.order?._id);
        setOrderTotal(response?.data?.order?.orderTotal);
        setLoading(false);
        setIsOrderCreated(true);
        setLoading(false);
        console.log('Order details:', response.data.order);
      } else {
        setLoading(false);
        alert('Failed to create order. Please check your cart and try again.');
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      setLoading(false);
      alert('An error occurred while processing your order. Please try again.');
    }
  };

  useEffect(() => {
    if (isOrderCreated && orderTotal > 0) {
      const handler = window.PaystackPop.setup({
        key: publicKey,
        email: email,
        amount: orderTotal * 100,
        currency: 'NGN',
        metadata: {
          custom_fields: [
            {
              display_name: firstName,
              variable_name: 'first_name',
              value: firstName,
            },
          ],
        },
        callback: function (response) {
          // Handle successful payment here
          handleSuccess(response);
        },
        onClose: function () {
          alert('Payment window closed');
        },
      });
      handler.openIframe();
    }
  }, [orderTotal, isOrderCreated]);

  const paymentSuccess = async () => {
    const url = `${api_url}/api/orders/${orderId}/pay/`;
    const options = {
      method: 'POST',
      body: {
        orderTotal
      },
    };

    try {
      const response = await fetchWithSession(url, options);
      setOrderTotal("");
      setIsOrderCreated(false);
      console.log("response", response);
      const message = `An order has been made with Order ID: ${response.data.order._id}`;
      const phoneNumber = '2347067255308'; // Recipient's phone number
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

      // Open WhatsApp with the pre-filled message
      window.open(whatsappUrl, '_blank');

      console.log("Order ID:", response.data.order._id);
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setDeliveryAddress("");
      setEmail("");
      setRegion("");
      setCity("");
      navigate("/merch");
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("failed", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      throw error;
    }
  };

  const handleSuccess = async () => {
    try {
      await paymentSuccess();
      toast.success('Payment Successful', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Payment failed", error);
    }
  };


  return (
    <section className="px-[4%] pb-[4%]">
      {loadingCart && (
        <p className="font-[400] text-[20px] workSans pt-5 pb-3 text-[#011114]">Loading order...</p>
      )}
      {cart && cart?.cart?.items.length > 0 ? (
        <div className="lg:flex items-start my-2 gap-4 justify-normal">
          <div className="p-6 bg-white shadow-md rounded">
            <div className="mb-4">
              <h2 className="text-xl font-semibold mb-2 workSans">Fill in your address</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <InputField label="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              <InputField label="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </div>
            <div className="mb-4">
              <InputField label="Delivery Address" value={deliveryAddress} onChange={(e) => setDeliveryAddress(e.target.value)} />
            </div>
            <div className="mb-4">
              <InputField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <InputField label="Region" type="select" selectOptions={regions} onChange={(e) => setRegion(e.target.value)} />
              <InputField label="City" value={city} onChange={(e) => setCity(e.target.value)} />
            </div>
            <div className="mb-4">
              <div className="flex items-start gap-6">
                <div>
                  <p className="workSans px-1 text-gray-700 text-sm">Prefix</p>
                  <p className="workSans ">+234</p>
                </div>
                <InputField label="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type="number" />
              </div>
            </div>
          </div>
          <div className="bg-white shadow-md rounded p-[24px] top-0 sticky">
            <p className="font-[400] text-[18px] workSans">Order Summary</p>
            <hr />
            <div className="flex items-center my-2 gap-2 justify-between">
              <p className="font-[400] text-[13px] workSans">item&apos;s total({totalQuantityProduct})</p>
              <p className="font-[500] text-[16px] workSans">$ {cart.cart.totalPrice.toFixed(2)}</p>
            </div>
            <hr />
            <div className="flex items-center my-2 gap-2 justify-between">
              <p className="font-[400] text-[15px] workSans">total</p>
              <p className="font-[500] text-[18px] workSans">$ {cart.cart.totalPrice.toFixed(2)}</p>
            </div>
            <hr />
            <button
              onClick={() => handleCheckout()}
              className="bg-[#E20BE3] w-full rounded-[11px] py-[12px] my-2 px-[96px] workSans font-[600] text-[14px] text-[#fff]"
            >
              {loading ? "loading..." : "Confirm order"}
            </button>
            <p className="font-[400] text-[12px] text-center workSans">(fill the form in order to proceed)</p>
          </div>
        </div>
      ) : (
        <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingCart ? "" : "No products found"}</div>
      )}
    </section>
  )
}

export default Checkout
