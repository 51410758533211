import React, { useState, useEffect } from 'react';
import { fetchOrders } from '../../redux/merch/order';
import { useSelector, useDispatch } from 'react-redux';
import Paid from './orderStatus/Paid';
import Pending from './orderStatus/Pending';

const Order = () => {
  const { orders, loadingOrders } = useSelector((state) => state.getOrders);
  const [tabNum, setTabNum] = useState(0);
  const dispatch = useDispatch();
  const userId = localStorage.getItem('userId');

  const paidOrders = orders?.filter((order) => order.paymentStatus === "Paid");
  const pendingOrders = orders?.filter((order) => order.paymentStatus === "Pending");


  useEffect(() => {
    if (userId) {
      dispatch(fetchOrders(userId));
    }
  }, [dispatch, userId])

  const switchTab = () => {
    switch (tabNum) {
      case 0:
        return <Paid paidOrders={paidOrders} />
      case 1:
        return <Pending pendingOrders={pendingOrders} />
      default:
        return <Paid />
    }
  }

  const handleTab = (num) => {
    setTabNum(num);
  }

  return (
    <section className="px-[4%] pb-[4%]">
      <div>
        <h1 className="text-[#121212]  text-[28px] font-[700] workSans">Orders</h1>
      </div>
      {loadingOrders && (
        <p className="font-[400] text-[20px] workSans pt-5 pb-3 text-[#011114]">Loading orders...</p>
      )}
      {orders && orders.length > 0 ? (
        <div className="w-full my-4 rounded shadow-md bg-[#fff] p-4">
          <div className="flex items-center gap-4">
            <button onClick={() => handleTab(0)} className={`text-[#75757A] ${tabNum === 0 ? 'text-[#E20BE3]' : ''} hover:text-[#E20BE3] text-[14px] workSans`}>
              PAID/DELIVERED ({paidOrders.length})
            </button>
            <button onClick={() => handleTab(1)} className={`text-[#75757A] ${tabNum === 1 ? 'text-[#E20BE3]' : ''} hover:text-[#E20BE3] text-[14px] workSans`}>
              FAILED PAYMENTS/CANCELED ({pendingOrders.length})
            </button>
          </div>
          <hr className="my-2" />
          {switchTab()}
        </div>
      ): (
        <div className="text-[#616161] text-[16px] font-[500] workSans">{loadingOrders ? "" : "No orders found"}</div>
      )}
    </section>
  )
}

export default Order;
