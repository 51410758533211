import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const SIGN_IN = 'SIGN_IN';
const api_url = process.env.REACT_APP_BASE_API_URL;
const SIGN_IN_URL = `${api_url}/api/auth/signin`;

const initialState = {
  userData: [],
  loading: false,
  error: false,
}

const setToken = (token) => {
  localStorage.setItem('token', token);
}

const setStreamToken = (streamToken) => {
  localStorage.setItem('streamToken', streamToken);
}

const setUserId = (userId) => {
  localStorage.setItem('userId', userId);
}

const removeToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userDetails');
}

export const getToken = () => {
  const userStr = localStorage.getItem('token');
  return userStr;
}

export const getUserId = () => {
  const userDet = localStorage.getItem('userId');
  return userDet;
}

export const signin = createAsyncThunk(
  SIGN_IN,
  async (user, { rejectWithValue }) => {
    try {
      const response = await axios.post(SIGN_IN_URL, user, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      const userToken = response?.data?.token;
      const streamToken = response?.data?.stream_token;

      const userId = response?.data?.userId;
      console.log("response", response?.data);

      setToken(userToken);
      setUserId(userId);
      setStreamToken(streamToken);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.error);
    }
  },
);

const signinReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SIGN_IN}/pending`:
      return {
        ...state,
        loading: true,
        error: false
      }
    case `${SIGN_IN}/fulfilled`:
      return {
        ...state,
        userData: action.payload,
        loading: false,
        error: false
      }
    case `${SIGN_IN}/rejected`:
      return {
        ...state,
        loading: false,
        error: true
      }
    default:
      return { userData: [], loading: false, error: false };
  }
}

export default signinReducer;
