import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResources } from '../../redux/resources/resources';
import { useParams, useNavigate } from 'react-router-dom';

const ResourceSchools = () => {
  const { resourceId } = useParams();
  const navigate = useNavigate();
  const { resources, loadingResources, resourcesError } = useSelector((state) => state.getResources);
  const resource = resources?.find((resource) => resource.schooltitle.toLowerCase().replace(/\s+/g, '-') === resourceId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchResources());
  }, [dispatch]);

  useEffect(() => {
    if (resources?.length > 0) {
      if (!resourceId || !resources.some(resource => resource.schooltitle.toLowerCase().replace(/\s+/g, '-') === resourceId)) {
        navigate("/resources");
      }
    }
  }, [resourceId, resources, navigate]);

  return (
    <div className="px-[4%]">
      {loadingResources && <p className="text-[#616161] text-[16px] font-[500] workSans">Loading courses...</p>}
      {resourcesError && <p className="text-[#616161] text-[16px] font-[500] workSans">Error loading courses</p>}
      {resource && (
        <div>
          <h1 className="text-[#011114] text-[40px] font-[400] baskerville text-center">{resource.schooltitle}</h1>
          <img src={resource.schoolimage} alt="course_img" className="w-full md:w-[1280px] md:h-[300px] rounded-[16px]" />
          <div className="flex items-center gap-4 py-[3%]">
            <p className="text-[#616161] workSans text-[16px] font-[500]">{resource.schooldifficulty}</p>
            <p className="text-[#616161] workSans text-[16px] font-[500]">|</p>
            <p className="text-[#616161] workSans text-[16px] font-[500]">{resource.schoolcourses.length} course{resource.schoolcourses.length > 1 ? 's' : ''}</p>
            <p className="text-[#616161] workSans text-[16px] font-[500]">|</p>
            <p className="text-[#616161] workSans text-[12px] bg-[#E20BE333] rounded-[8px] px-[8px] py-[2px] font-[500]">{resource.schoolcategory}</p>
          </div>
          <p className="font-[600] text-[32px] mb-4 workSans text-[#011114]">GENERAL OVERVIEW</p>
          <p className="text-[#011114] text-[16px] font-[400] workSans">
            {resource.schooldesc}
          </p>
          <p className="font-[600] text-[32px] mt-4 workSans text-[#011114]">COURSES</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {resource.schoolcourses.map((course, index) => (
              <div
                key={index}
                className="bg-[#5900640D] cursor-pointer w-full md:w[336px] h-full rounded-tr-[8px] rounded-tl-[8px] p-[40px]"
                onClick={() => navigate(`/resources/school/${resource.schooltitle.toLowerCase().replace(/\s+/g, '-')}/course/${course.coursetitle.toLowerCase().replace(/\s+/g, '-')}`)}
              >
                <p className="font-[600] text-[32px] workSans text-[#011114]">{course.coursetitle}</p>
                <p className="text-[#011114] workSans text-[16px] font-[400]">
                  {course.coursedesc}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResourceSchools;
