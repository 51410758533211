import React from 'react';
import paystackLogo from "../../components/images/paystackLogo.jpeg";
import tetherLogo from "../../components/images/tether-usdt-logo.png";

const PaymentOptions = ({ setPage }) => {
  const paymentOptions = [
    {
      gateway: "Paystack",
      logo: paystackLogo,
      processingTime: "instant - 30 minutes",
      fee: "0%",
      pageId: 1,
    },
    {
      gateway: "Tether (USDT TRC20)",
      logo: tetherLogo,
      processingTime: "instant - 1 day",
      fee: "0%",
      pageId: 2,
    }
  ];

  return (
    <div>
      <p className="text-[#011114] text-[16px] pb-4 font-[400] workSans">
        Kindly use any of the payment option to make your donation
      </p>
      <div className="flex flex-wap gap-6 items-baseline">
        {paymentOptions.map((payment, index) => (
          <div
            key={index}
            onClick={() => setPage(payment.pageId)}
            className="border-[#D9D9D9] hover:shadow-xl cursor-pointer max-w-[350px] h-full my-2 border-[1px] rounded-[8px] w-full py-3 px-3">
            <div className="flex items-center gap-4">
              <img className="w-[30px]" src={payment.logo} alt="paystack logo" />
              <p className="text-[#BFBBBB] text-[20px] font-[500] workSans">{payment.gateway}</p>
            </div>
            <hr className="my-4" />
            <div>
              <p className="text-[#BFBBBB] text-[12px] workSans">Processing time  {payment.processingTime}</p>
              <p className="text-[#BFBBBB] text-[12px] workSans">Fee  {payment.fee}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentOptions;
