import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const VERIFY_OTP = 'VERIFY_OTP';
const api_url = process.env.REACT_APP_BASE_API_URL;
const VERIFY_OTP_URL = `${api_url}/api/auth/verifyOtp`;

const initialState = {
  verifiedOtp: [],
  loading: false,
  error: false,
}

export const verifyOtp = createAsyncThunk(
  VERIFY_OTP,
  async (otpData, { rejectWithValue }) => {
    try {
      const response = await axios.post(VERIFY_OTP_URL, otpData, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(otpData),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  },
);

const verifyOtpReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${VERIFY_OTP}/pending`:
      return {
        ...state,
        loading: true,
        error: false
      }
    case `${VERIFY_OTP}/fulfilled`:
      return {
        ...state,
        verifiedOtp: action.payload,
        loading: false,
        error: false
      }
    case `${VERIFY_OTP}/rejected`:
      return {
        ...state,
        loading: false,
        error: true
      }
    default:
      return { verifiedOtp: [], loading: false, error: false };
  }
}

export default verifyOtpReducer;
