import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchEvents } from '../../redux/events/events';

const LiveEvent = () => {
  const currentDate = new Date().toISOString().split('T')[0];
  const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

  const { events, loadingEvents } = useSelector((state) => state.getEvents);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchEvents())
  }, [dispatch]);

  const filteredEvents = !loadingEvents && events.length > 0
    ? events.filter((event) => {
      const eventDate = event.sessiondate.split('T')[0];
      return event.locationcategory === 'Webinar' && eventDate === currentDate;
    })
    : [];
  const isButtonDisabled = filteredEvents[0]?.sessiontime > currentTime;

  return (
    <div className="py-[2%] px-[4%]">
      {loadingEvents && (
        <p className="font-[600] text-[15px] workSans text-[#011114]">Loading Webinar...</p>
      )}
      {filteredEvents.length > 0 ? (
        filteredEvents.slice(0, 1).map(event => (
          <div key={event._id} className="bg-[#04030408] h-full rounded-[32px] my-4 p-[24px]">
            <img src={event.eventimage} alt="event_image" className="w-full h-[250px] rounded-[8px]" />
            <div className="py-[3%]">
              <p className="font-[600] text-[32px] workSans text-[#011114]">{event.sessionname}</p>
              <p className="font-[400] text-[16px] workSans text-[#011114]">{event.sessiondetails}</p>
              <p className="text-[#011114] font-[700] text-[14px] workSans">{event.sessiontime}</p>
            </div>
            <button
              type="button"
              onClick={() => navigate(`/live_session/${event._id}`)}
              disabled={isButtonDisabled}
              className={`linearGradient ${isButtonDisabled ? "cursor-not-allowed" : ""} rounded-[32px] text-[14px] font-[500] workSans text-[#fff]`}
            >
              {event.sessiontime > currentTime ? `Starts at ${event.sessiontime}` : 'Join'}
            </button>
          </div>
        ))
      ) : (
        <div className="font-[400] text-[16px] workSans text-[#011114]">{loadingEvents ? "" : "No webinar today"}</div>
      )}
    </div>
  )
}

export default LiveEvent
