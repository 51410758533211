import axios from 'axios';

export const fetchWithSession = async (url, options = {}) => {
  const sessionToken = localStorage.getItem('token');

  try {
    const response = await axios(url, {
      // ...options,
      method: options.method || 'GET',
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${sessionToken}`,
      },
      data: options.body,
    });

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Handling session expiration (e.g., redirect to login)
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('streamToken');
      // alert('Session expired. Please log in again.');
      // window.location.href = '/auth/signin';
    } else {
      throw error;
    }
  }
};
