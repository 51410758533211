import React, { useState } from 'react';
import psLogoWhite from "./images/logowhite.png";
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import "./index.css";

const Navbar = () => {
  const style = {
    list: "workSans text-[16px] font-[500] text-[#fff]"
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="flex justify-between md:justify-center flex-wrap items-center gap-6 p-4">
      <Link to="/"><img src={psLogoWhite} alt="Logo" /></Link>
      <button
        className="block md:hidden focus:outline-none"
        onClick={toggleMenu}
      >
        {isMenuOpen ? (<IoMdClose className="text-white text-[25px]" />) : (<FaBars className="text-white text-[25px]" />)}
      </button>
      <a href="https://t.me/+8svS-BjpWZ1iODY0" target='_blank' className="hidden md:block">
        <button type="button" className="bg-[#FFFFFF] rounded-lg px-[25px] py-[10px] text-[14px] font-[400] workSans text-[#6D6662]">
          Join community
        </button>
      </a>
      <ul className="hidden md:flex gap-6">
        <Link to="/auth/signin"><li className={style.list}>Live sessions</li></Link>
        <Link to="/auth/signin"><li className={style.list}>Resources</li></Link>
        <Link to="/auth/signin"><li className={style.list}>Upcoming events</li></Link>
        <Link to="/auth/signin"><li className={style.list}>Shop</li></Link>
        <Link to="/"><li className={style.list}>Contact us</li></Link>
      </ul>
      <div className="hidden md:flex items-center gap-8">
        <Link to="/auth/signup">
          <button type="button" className="signUp rounded-[32px] padding text-[14px] font-[500] workSans text-[#fff]">
            Sign up
          </button>
        </Link>
        <Link to="/auth/signin">
          <button type="button" className="rounded-[32px] border-[2px] border-[#C60AC7] px-[25px] py-[11px] text-[14px] font-[500] workSans signIn">
            Log in
          </button>
        </Link>
      </div>
      {/* Mobile Menu */}
      <div
        className={`fixed z-50 top-0 left-0 w-2/3 h-full bg-black p-6 transform transition-transform duration-300 ease-in-out ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'
          } md:hidden`}
      >
        <Link to="/" onClick={toggleMenu}><img src={psLogoWhite} className="my-4" alt="Logo" /></Link>
        <a onClick={toggleMenu} href="https://t.me/+8svS-BjpWZ1iODY0" target='_blank' className="">
          <button type="button" className="bg-[#FFFFFF] rounded-lg px-[25px] py-[10px] text-[14px] font-[400] workSans text-[#6D6662]">
            Join community
          </button>
        </a>
        <ul className="flex flex-col gap-6 mt-10">
          <Link onClick={toggleMenu} to="/auth/signin"><li className={style.list}>Live sessions</li></Link>
          <Link onClick={toggleMenu} to="/auth/signin"><li className={style.list}>Resources</li></Link>
          <Link onClick={toggleMenu} to="/auth/signin"><li className={style.list}>Upcoming events</li></Link>
          <Link onClick={toggleMenu} to="/auth/signin"><li className={style.list}>Shop</li></Link>
          <Link onClick={toggleMenu} to="/"><li className={style.list}>Contact us</li></Link>
        </ul>
        <div className="flex flex-col gap-4 mt-6">
          <Link onClick={toggleMenu} to="/auth/signup">
            <button type="button" className="signUp rounded-[32px] padding text-[14px] font-[500] workSans text-[#fff]">
              Sign up
            </button>
          </Link>
          <Link onClick={toggleMenu} to="/auth/signin">
            <button type="button" className="rounded-[32px] border-[2px] border-[#C60AC7] px-[25px] py-[11px] text-[14px] font-[500] workSans signIn">
              Log in
            </button>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
