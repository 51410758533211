import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideos } from '../../redux/videos/getVideos';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import axios from 'axios';

const WebinarDetails = () => {
  const { videoCourseId } = useParams();
  const navigate = useNavigate();
  const { videos, loadingVideos, videosError } = useSelector((state) => state.getVideos);
  const video = videos?.find((video) => video._id === videoCourseId);
  const dispatch = useDispatch();
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const viewTimeoutRef = useRef(null);

  useEffect(() => {
    dispatch(fetchVideos());
  }, [dispatch]);

  useEffect(() => {
    if (videos?.length > 0) {
      if (!videoCourseId || !videos.some(video => video._id === videoCourseId)) {
        navigate("/video_courses");
      }
    }
  }, [videoCourseId, videos, navigate]);

  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "do MMMM, yyyy");
  };

  const incrementViews = async (videoId) => {
    try {
      await axios.post(`${api_url}/api/livevideo/${videoId}/`);
      console.log("sent")
    } catch (error) {
      console.error('Failed to increment views', error);
    }
  };

  const handlePlay = (videoId) => {
    if (viewTimeoutRef.current) {
      clearTimeout(viewTimeoutRef.current);
    }

    const viewedVideos = JSON.parse(localStorage.getItem('viewedVideos') || '[]');
    if (!viewedVideos.includes(videoId)) {
      viewTimeoutRef.current = setTimeout(() => {
        incrementViews(videoId);
        viewedVideos.push(videoId);
        localStorage.setItem('viewedVideos', JSON.stringify(viewedVideos));
      }, 10000);
    }
  };
  const filteredVideos = videos?.filter((v) => v.category === video?.category);

  return (
    <div className="px-[4%]">
      <h1 className="text-[#121212] text-[28px] pt-6 py-6 font-[700] workSans">Video Session</h1>
      {loadingVideos && <p className="text-[#616161] text-[16px] font-[500] workSans">Loading Video...</p>}
      {videosError && <p className="text-[#616161] text-[16px] font-[500] workSans">Error loading Video</p>}
      {video && (
        <div className="bg-[#5900641A] w-fit rounded-[24px] lg:flex items-start gap-4 p-[24px]" key={video._id}>
          <video
            className="md:w-[500px] w-full md:h-[440px] h-[250px] z-50"
            controls
            onPlay={() => handlePlay(video._id)}
            onPause={() => clearTimeout(viewTimeoutRef.current)}
            onEnded={() => clearTimeout(viewTimeoutRef.current)}
          >
            <source src={video.videourl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div>
            <p className="font-[600] text-[32px] workSans pt-5 text-[#011114]">{video.videotitle}</p>
            <p className="text-[#616161] text-[14px] font-[400] workSans">Uploaded {formatDate(video.createdAt)}</p>
            <div className="flex flex-wrap items-center gap-4 py-5">
              <p className="text-[rgb(97,97,97)] workSans text-[16px] font-[500]">{video.difficulty}</p>
              <p className="text-[#616161] workSans text-[16px] font-[500]">|</p>
              <p className="text-[#616161] workSans text-[16px] font-[500]">{video.duration}</p>
              <p className="text-[#616161] workSans text-[16px] font-[500]">|</p>
              <p className="text-[#616161] workSans text-[16px] font-[500]">{video.views} view{video.views.length !== 0 ? 's' : ''}</p>
              <p className="text-[#616161] workSans text-[12px] bg-[#E20BE333] rounded-[8px] px-[8px] py-[2px] font-[500]">{video.category}</p>
            </div>
            <div className="">
              <p className="text-[#011114] font-[400] workSans text-[16px]">
                {video.videodescription}
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="py-[3%]">
        <p className="baskerville font-[400] text-center text-[40px] mt-4 text-[#000]">
          Similar
          <span className="heading"> Videos</span>
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 py-6">
        {filteredVideos && filteredVideos.map((video) => (
          <div className="bg-[#5900641A] rounded-[24px] p-[24px]" key={video._id}>
            <video
              className="w-full h-[250px] z-50 custom-video"
              controls
              onPlay={() => handlePlay(video._id)}
              onPause={() => clearTimeout(viewTimeoutRef.current)}
              onEnded={() => clearTimeout(viewTimeoutRef.current)}
            >
              <source src={video.videourl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="cursor-pointer" onClick={() => navigate(`/webinar/${video._id}`)}>
              <p className="font-[600] text-[32px] workSans pt-5 text-[#011114]">{video.videotitle}</p>
              <p className="text-[#616161] text-[14px] font-[400] workSans">Uploaded {formatDate(video.createdAt)}</p>
              <div className="flex flex-wrap items-center gap-4 py-5">
                <p className="text-[rgb(97,97,97)] workSans text-[16px] font-[500]">{video.difficulty}</p>
                <p className="text-[#616161] workSans text-[16px] font-[500]">|</p>
                <p className="text-[#616161] workSans text-[16px] font-[500]">{video.duration}</p>
                <p className="text-[#616161] workSans text-[16px] font-[500]">|</p>
                <p className="text-[#616161] workSans text-[16px] font-[500]">{video.views} view{video.views.length !== 0 ? 's' : ''}</p>
                <p className="text-[#616161] workSans text-[12px] bg-[#E20BE333] rounded-[8px] px-[8px] py-[2px] font-[500]">{video.category}</p>
              </div>
              <div className="">
                <p className="text-[#011114] font-[400] workSans text-[16px]">
                  {limitWords(video.videodescription, 24)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WebinarDetails;
