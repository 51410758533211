import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithSession } from '../session';

const GET_EVENTS = "GET_EVENTS";
const api_url = process.env.REACT_APP_BASE_API_URL;
const GET_EVENTS_URL = `${api_url}/api/events`;

const initialState = {
  events: [],
  loadingEvents: false,
  eventsError: false,
}

export const fetchEvents = createAsyncThunk(
  GET_EVENTS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWithSession(`${GET_EVENTS_URL}`, {
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const getEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_EVENTS}/pending`:
      return {
        ...state,
        loadingEvents: true,
        eventsError: false
      }
    case `${GET_EVENTS}/fulfilled`:
      return {
        events: action.payload,
        loadingEvents: false,
        eventsError: false
      }
    case `${GET_EVENTS}/rejected`:
      return {
        ...state,
        loadingEvents: false,
        eventsError: true
      }
    default:
      return { ...state, loadingEvents: false, eventsError: false };
  }
}

export default getEventsReducer;
