import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import paystackLogo from "../../images/paystackLogo.jpeg";
import stripeLogo from "../../images/Stripe_logo.png";
import cryptoLogo from "../../images/png-transparent-bitcoin-com-cryptocurrency-logo-zazzle-bitcoin-text-trademark-logo-thumbnail.png";

const PaymentOptions = ({ setPage }) => {
  const { userId } = useParams();
  const [response, setResponse] = useState(null);
  const api_url = process.env.REACT_APP_BASE_API_URL;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const amount = "10";
  const currency = "USD";

  const paymentOptions = [
    {
      gateway: "Paystack",
      logo: paystackLogo,
      processingTime: "instant - 30 minutes",
      fee: "0%",
      pageId: 1,
    },
    {
      gateway: "Crypto Payment",
      logo: cryptoLogo,
      processingTime: "instant - 1 day",
      fee: "0%",
      pageId: 2,
    },
    {
      gateway: "Stripe",
      logo: stripeLogo,
      processingTime: "instant - 1 hour",
      fee: "$0.59",
      pageId: 3,
    }
  ];

  const handleCryptoPayment = async () => {
    setLoading(true);
    try {
      const data = { amount, currency };
      const res = await axios.post(`${api_url}/api/auth/crypto-payment-intent/${userId}`, data);

      setResponse(res);
      if (res?.data && res?.data?.result?.url) {
        window.location.href = res?.data?.result?.url;
      }
      setLoading(false);
      setError(null);
    } catch (err) {
      setError(err?.response ? err?.response?.data : 'Error making payment');
      setResponse(null);
      setLoading(false);
    }
  };

  return (
    <div>
      <p className="text-[#011114] text-[16px] pb-4 font-[400] workSans">
        Please deposit an amount of $10 to activate your account on our platform.
      </p>
      <div className="flex flex-wrap gap-6 items-baseline">
        {paymentOptions.map((payment, index) => (
          <div
            key={index}
            onClick={() => payment.gateway === "Crypto Payment" ? handleCryptoPayment() : setPage(payment.pageId)}
            className="border-[#D9D9D9] hover:shadow-xl cursor-pointer max-w-[350px] h-full my-2 border-[1px] rounded-[8px] w-full py-3 px-3">
            <div className="flex items-center gap-4">
              <img className="w-[30px]" src={payment.logo} alt="paystack logo" />
              <p className="text-[#BFBBBB] text-[20px] font-[500] workSans">{payment.gateway}</p>
            </div>
            <hr className="my-4" />
            <div>
              <p className="text-[#BFBBBB] text-[12px] workSans">Processing time  {payment.processingTime}</p>
              {/* <p className="text-[#BFBBBB] text-[12px] workSans">Fee  {payment.fee}</p> */}
            </div>
            {loading && payment.gateway === "Crypto Payment" && (
              <div className="loading-spinner">
                <p className="text-[#BFBBBB] text-[16px] workSans">Loading gateway...</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentOptions;
