import React, { useState } from 'react';
import logo from "../../images/logoblack.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const Password = ({
  setPage, password,
  setPassword, createUser
}) => {
  const [confirmpassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePassword = () => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+[\]{};:'",.<>/?\\|`~])[A-Za-z\d!@#$%^&*()\-_=+[\]{};:'",.<>/?\\|`~]{8,}$/;

    setLoading(true);
    // Validate password
    if (!passwordRegex.test(password)) {
      toast.warning("Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      return;
    }

    if (confirmpassword !== password) {
      toast.warning("password do not match", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
      return
    }
    createUser();
    setLoading(false);
    setConfirmPassword("");
  }

  const areFieldsEmpty = () => {
    return !password || !confirmpassword;
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex justify-between gap-2 items-center">
        <Link to="/"><img src={logo} alt="logo" /></Link>
        <button
          onClick={() => setPage(2)}
          type="button"
          className={`px-2 py-[4px] border-[#616161] text-[#011114] border-[1px] bg-[#fff] rounded-[50%]`}
        >
          &larr;
        </button>
      </div>
      <p className="text-[#011114] text-[30px] font-[700] workSans my-3">Sign up</p>
      <p className="text-[#011114] text-[16px] pb-4 font-[400] workSans">
        Enter your password
      </p>
      <div className="mb-4">
        <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="password">
          Enter Password
        </label>
        <input
          className="appearance-none border-[#D9D9D9] border-[1px] rounded-[8px] w-full md:w-[400px] py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder="********"
        />
      </div>
      <div className="mb-4">
        <label className="block text-[#616161] text-[14px] font-[600] workSans mb-2" htmlFor="confirmpassword">
          Confirm Password
        </label>
        <input
          className="appearance-none border-[#D9D9D9] border-[1px] rounded-[8px] w-full md:w-[400px] py-3 px-3 text-[#3d3d3d] text-[16px] font-[400] workSans leading-tight focus:outline-none"
          id="confirmpassword"
          value={confirmpassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          placeholder="********"
        />
      </div>
      <button
        type="button"
        onClick={handlePassword}
        className={`linearGradient rounded-[32px] w-full text-[14px] font-[500] workSans text-[#fff] ${areFieldsEmpty() ? 'cursor-not-allowed' : ''}`}
        disabled={areFieldsEmpty()}
      >
        {loading ? "loading..." : "next"}
      </button>
    </div>
  );
};

export default Password;
