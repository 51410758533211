import React, { useState } from 'react';
import verifiedBadge from "../components/images/verified.png";
import PaymentOptions from './paymentProcess/PaymentOptions';
import Paystack from './paymentProcess/Paystack';
import Tether from './paymentProcess/Tether';
import logo from "../components/images/logoblack.png";

const Donate = () => {
  const [page, setPage] = useState(0);
  const [viewPaymentModal, setViewPaymentModal] = useState(false)

  const currentPage = () => {
    switch (page) {
      case 0:
        return <PaymentOptions setPage={setPage} />
      case 1:
        return <Paystack setPage={setPage} setViewPaymentModal={setViewPaymentModal} />
      case 2:
        return <Tether setPage={setPage} />
      default:
        return <PaymentOptions />
    }
  };

  return (
    <div className="homepageHero2 min-h-screen px-[5%]">
      <div className="flex justify-center">
        <div className="bg-[#FFFFFF] md:w-[700px] gap-[5%] rounded-[24px] p-6 my-[5%]">
          <div>
            <img src={logo} alt="logo" />
            <p className="text-[#011114] text-[30px] font-[700] workSans my-3">Donation</p>
            <div>
              {currentPage()}
            </div>
          </div>
        </div>
      </div>
      {viewPaymentModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm md:w-full">
            <div className="flex flex-col items-center">
              <img src={verifiedBadge} alt="badge" className="mb-4" />
              <p className="text-[#616161] text-[16px] text-center pt-4 pb-4 font-[400] workSans">Your payment was successful</p>
              <p className="text-[#616161] text-[16px] text-center pt-4 pb-4 font-[400] workSans">Thank you for donating</p>
              <button
                type="button"
                className="linearGradient rounded-[32px] w-full text-[14px] mt-[25%] font-[500] workSans text-[#fff]"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Donate;
